import { useContext, useEffect, useRef, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  Input,
  PasswordValidationPopover,
  handlePopoverData,
  asyncValidateSchema,
  LoadingSpinner,
} from '@rabbit/elements/shared-components';
import { FIREBASE_AUTH_ERRORS } from '../../../utils/consts';
import { FirebaseError } from 'firebase/app';
import { SAGE_ROUTE_NAME } from '@rabbit/config/enums';
import { UserContext } from 'apps/sage/src/context/UserContext';
import {
  DTBusiness_User_Invite,
  SELF_REGISTERED_VENDABLE,
} from '@rabbit/data/types';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AppContext } from '@rabbit/app-context';
import { useQueryParams } from 'raviger';
import {
  nestApiDecodeBusinessUserInviteLink,
  BL_Warranty,
  nestApiBusinessUserSignup,
} from '@rabbit/bizproc/core';
import { FBD_User_Request } from '../../../../../../libs/data/types/src/lib/user-request.ts';
import { DR_Holding_Proxy } from '@rabbit/data/portal';

export interface SageAuthFormValuesShape {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  platform_services_terms: boolean;
  rememberMe: boolean;
}

let initialValues: SageAuthFormValuesShape = {
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  platform_services_terms: false,
  rememberMe: true,
};

export interface AuthFormProps {
  kind: 'Login' | 'Signup' | 'Partner-Signup' | 'Customer-Signup';
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  authErrorMsg: string;
  setAuthErrorMsg: React.Dispatch<React.SetStateAction<string>>;
}

export function AuthForm({
  kind,
  setIsLoading,
  authErrorMsg,
  setAuthErrorMsg,
}: AuthFormProps) {
  const [buttonLoading, setButtonLoading] = useState(false);
  const contextValues = useContext(UserContext);
  const { config, tenantInfo } = useContext(AppContext);
  const { createSageRepairerUser, sageSignIn } = contextValues || {};
  const queryParams = new URLSearchParams(location.search);
  const formikRef = useRef(null) as any;
  const [inviteData, setInviteData] = useState<DTBusiness_User_Invite>();
  const { t } = useTranslation();
  const params = useQueryParams()[0];
  let registerProductSku = '';
  if (params.sku) {
    registerProductSku += `sku=${params.sku}`;
  }
  if (params.sn) {
    registerProductSku += `&sn=${params.sn}`;
  }

  const navigate = useNavigate();

  const validationSchema = () =>
    Yup.object().shape({
      email: Yup.string()
        .email(t('message.pleaseEnterAValidEmailAddress'))
        .required(t('message.pleaseEnterYourEmailAddress')),
      password: Yup.string()
        .min(8, t('message.passwordShouldBe8OrMoreCharacters'))
        .matches(/(?=.*[a-z])/, t('message.pleaseEnterAtLeast1LowercaseLetter'))
        .matches(/(?=.*[A-Z])/, t('message.pleaseEnterAtLeast1UppercaseLetter'))
        .matches(/^(?=.*[0-9])/, t('message.pleaseEnterAtLeast1Number'))
        .required(t('message.pleaseEnterYourPassword')),
    });

  const validationSchemaSignup = () =>
    Yup.object().shape({
      first_name: Yup.string()
        .trim()
        .required(t('message.pleaseEnterFirstName'))
        .matches(
          /^(?:[A-Z][a-z]*['´`-]?\s?){1,2}|^[A-Z][a-z]*$/,
          t('message.firstNameMustStartWithCapitalAndContainOnlyLetters')
        ),
      last_name: Yup.string()
        .trim()
        .required(t('message.pleaseEnterLastName'))
        .matches(
          /^(?:[A-Z][a-z]*['´`-]?\s?){1,2}|^[A-Z][a-z]*$/,
          t('message.lastNameMustStartWithCapitalAndContainOnlyLetters')
        ),
      email: Yup.string()
        .email(t('message.pleaseEnterAValidEmailAddress'))
        .required(t('message.pleaseEnterYourEmailAddress')),
      password: Yup.string()
        .min(8, t('message.passwordShouldBe8OrMoreCharacters'))
        .matches(/(?=.*[a-z])/, t('message.pleaseEnterAtLeast1LowercaseLetter'))
        .matches(/(?=.*[A-Z])/, t('message.pleaseEnterAtLeast1UppercaseLetter'))
        .matches(/^(?=.*[0-9])/, t('message.pleaseEnterAtLeast1Number'))
        .required(t('message.pleaseEnterYourPassword')),
      platform_services_terms: Yup.bool().isTrue(t('message.acceptTerms')),
    });

  const parseFirebaseAuthErrors = (errorCode: string) => {
    switch (errorCode) {
      case FIREBASE_AUTH_ERRORS.EMAIL_EXISTS:
        return t('message.pleaseUseDifferentEmailToCreateYourAccount');
      case FIREBASE_AUTH_ERRORS.INVALID_PASSWORD:
        return t('message.invalidCredentialsCheckEmailPasswordAndTryAgain');
      case FIREBASE_AUTH_ERRORS.INVALID_EMAIL:
        return t('message.invalidCredentialsCheckEmailPasswordAndTryAgain');
      case FIREBASE_AUTH_ERRORS.TOO_MANY_ATTEMPTS_TRY_LATER:
        return t('message.tooManyAttemptsTryAgainLater');
      case FIREBASE_AUTH_ERRORS.USER_DELETED:
        return t('message.invalidCredentialsCheckEmailPasswordAndTryAgain');
      default:
        return t('message.somethingWentWrongPleaseTryAgain');
    }
  };

  if (!createSageRepairerUser || !sageSignIn)
    return <LoadingSpinner size="sm" />;

  const onSubmit = async (values: SageAuthFormValuesShape) => {
    setButtonLoading(true);
    setIsLoading(true);
    if (kind === 'Login') {
      try {
        await sageSignIn(values);
      } catch (err) {
        if (err instanceof FirebaseError) {
          setIsLoading(false);
          setAuthErrorMsg(parseFirebaseAuthErrors(err.code));
        } else console.log(err);
      } finally {
        setButtonLoading(false);
      }
    }
    if (
      kind === 'Signup' ||
      kind === 'Partner-Signup' ||
      kind === 'Customer-Signup'
    ) {
      if (!values.platform_services_terms) {
        setAuthErrorMsg(
          t(
            'message.pleaseAgreeToPlatformServicesTermsBeforeCreatingYourAccount'
          )
        );
        setIsLoading(false);
        setButtonLoading(false);
        return;
      }
      if (
        !checkIfAllowedEmailForUser(
          values.email,
          import.meta.env.VITE_FIREBASE_MODE
        )
      ) {
        setAuthErrorMsg(t('message.somethingWentWrongPleaseTryAgain'));
        setIsLoading(false);
        setButtonLoading(false);
        return;
      }
      try {
        if (
          (kind === 'Partner-Signup' || kind === 'Customer-Signup') &&
          inviteData
        ) {
          const res = (await nestApiBusinessUserSignup({
            inviteData,
            premiumTenant: t('tenantLink'),
            password: values.password,
            firstName: values.first_name,
            lastName: values.last_name,
          })) as any;
          if (res) {
            toast.success(
              t('message.accountCreatedSuccessfullyPleaseLoginToContinue')
            );
            navigate(SAGE_ROUTE_NAME.LOGIN);
          } else {
            toast.error(
              t('message.somethingWentWrongPleaseTryAgain') +
                res.error.replace(/^.*Error: /, '') +
                '.'
            );
          }
        } else {
          await createSageRepairerUser(values);
        }
      } catch (err) {
        if (err instanceof FirebaseError) {
          setAuthErrorMsg(parseFirebaseAuthErrors(err.code));
          setIsLoading(false);
        } else console.log(err);
      } finally {
        setIsLoading(false);
        setButtonLoading(false);
      }
    }
  };

  useEffect(() => {
    if (kind === 'Partner-Signup' || kind === 'Customer-Signup') {
      const getUserDetails = async () => {
        const c = queryParams.get('c') || '',
          iv = queryParams.get('iv') || '';

        const res = (await nestApiDecodeBusinessUserInviteLink({
          c,
          iv,
        })) as any;

        if (res.inviteData) {
          setInviteData(res.inviteData as DTBusiness_User_Invite);
        }
      };
      getUserDetails();
    }
  }, []);

  useEffect(() => {
    if (
      inviteData?.email &&
      (kind === 'Partner-Signup' || kind === 'Customer-Signup')
    ) {
      formikRef?.current?.setFieldValue('email', inviteData?.email);
      if (inviteData?.firstName) {
        formikRef?.current?.setValues({
          first_name: inviteData?.firstName,
          last_name: inviteData?.lastName,
          email: inviteData?.email,
        });
      }
    }
  }, [inviteData]);

  if (
    (kind === 'Partner-Signup' || kind === 'Customer-Signup') &&
    !inviteData?.docid
  )
    return <LoadingSpinner size="sm" />;

  return (
    <Formik
      initialValues={initialValues}
      validate={asyncValidateSchema(
        kind === 'Login' ? validationSchema() : validationSchemaSignup()
      )}
      validateOnBlur
      validateOnChange={true}
      validateOnMount={true}
      onSubmit={onSubmit}
      innerRef={formikRef}
      enableReinitialize={true}
    >
      {({ errors, values }) => (
        <Form className="font-nunito flex flex-col gap-3">
          {(kind === 'Signup' ||
            kind === 'Partner-Signup' ||
            kind === 'Customer-Signup') && (
            <>
              <Input
                type="text"
                name="first_name"
                label={`${t('general.firstName')}*`}
                settings={{
                  placeholder: t('message.pleaseEnterFirstName'),
                  forceUpperCaseFirstLetter: true,
                }}
              />
              <Input
                type="text"
                name="last_name"
                label={t('general.lastName') + '*'}
                settings={{
                  placeholder: t('message.pleaseEnterLastName'),
                  forceUpperCaseFirstLetter: true,
                }}
              />
            </>
          )}
          <div className="flex flex-col gap-6">
            <Input
              type="email"
              name="email"
              label={kind === 'Login' ? '' : t('general.email') + '*'}
              settings={{
                placeholder: t('general.email'),
                disabled:
                  kind === 'Partner-Signup' || kind === 'Customer-Signup',
              }}
            />
            <Input
              type="password"
              name="password"
              label={kind === 'Login' ? '' : t('general.password') + '*'}
              settings={{
                placeholder: t('general.password'),
                id: 'password',
                popoverTrigger: 'hover',
                popoverPlacement: 'right',
                popoverTarget: 'popover-password',
              }}
            />
          </div>
          <PasswordValidationPopover
            id="popover-password"
            errors={handlePopoverData(errors.password, values.password)}
          />
          {(kind === 'Signup' ||
            kind === 'Partner-Signup' ||
            kind === 'Customer-Signup') && (
            <div className="my-2 w-full justify-between">
              <Input
                type="checkbox"
                name="platform_services_terms"
                settings={{
                  checkboxLabel:
                    t('message.byCreatingIWarrantyAccountYouAgreeToThe') +
                    ' <a href="' +
                    SAGE_ROUTE_NAME.PLATFORM_SERVICES_AGREEMENT +
                    '" target="_blank" style="font-weight:600;color:rgb(31 107 92 / var(--tw-text-opacity))" />' +
                    t('message.platformServicesTerms') +
                    '</a>.',
                  checkboxLabelStyles: 'text-base text-gray-500',
                }}
              />
            </div>
          )}
          {kind === 'Login' && (
            <div className="my-2 flex w-full justify-between">
              <div className="shrink-0">
                <Input
                  type="checkbox"
                  name="rememberMe"
                  settings={{
                    checkboxLabel: t('message.rememberMe'),
                    checkboxLabelStyles: 'text-base text-gray-500',
                  }}
                />
              </div>
              <div className="shrink-0">
                <NavLink
                  to={SAGE_ROUTE_NAME.FORGOT_PASSWORD}
                  className="text-primary-800 font-medium no-underline hover:underline"
                >
                  {t('message.forgotPassword')}
                </NavLink>
              </div>
            </div>
          )}
          <Button
            kind="primary"
            type="submit"
            disabled={
              Object.keys(errors).length > 0 ||
              values.email.length === 0 ||
              buttonLoading
            }
            loading={buttonLoading}
          >
            {kind === 'Login' ? t('message.logIn') : t('message.createAccount')}
          </Button>
          {kind === 'Login' && (
            <>
              <div className="w-full justify-between text-sm text-gray-500">
                {t('message.byLoggingIntoIWarrantyYouAreAgreeingToThe')}
                <a
                  href={SAGE_ROUTE_NAME.PLATFORM_SERVICES_AGREEMENT}
                  target="_blank"
                  className="text-primary-800 flex font-bold no-underline hover:underline"
                >
                  {t('message.platformServicesTerms')}
                </a>
              </div>
              {config.HOLDINGS.INSTALLATION_REQUIRED && registerProductSku && (
                <div className="text-md w-full justify-between font-bold text-gray-500">
                  {t('message.dontHave')}.{' '}
                  <a
                    href={`${SAGE_ROUTE_NAME.REGISTER_PRODUCT_INSTALLER}${
                      registerProductSku ? '?' + registerProductSku : ''
                    }`}
                  >
                    {t('message.clickHere')}
                  </a>{' '}
                  {t('message.registerProduct')}.
                </div>
              )}
            </>
          )}
          {authErrorMsg && (
            <p className="font-nunito text-sm text-red-500">{authErrorMsg}</p>
          )}
        </Form>
      )}
    </Formik>
  );
}

export default AuthForm;

/* -------------------------------------------------------------------------- */
/*                                   Helpers                                  */
/* -------------------------------------------------------------------------- */

const checkIfAllowedEmailForUser = (email: string, firebase_mode: string) => {
  if (
    firebase_mode === 'EMULATOR' ||
    firebase_mode === 'LIVE' ||
    firebase_mode === 'EULIVE' ||
    firebase_mode === 'EUSANDBOX' ||
    firebase_mode === 'EULAUNCHPAD' ||
    firebase_mode === 'USLIVE' ||
    firebase_mode === 'USSANDBOX' ||
    firebase_mode === 'USLAUNCHPAD' ||
    firebase_mode === 'DEMO'
  )
    return true;
  if (
    email.includes('@iwarranty.co') ||
    email.includes('@studiographene.com') ||
    email.includes('@sharklasers.com')
  )
    return true;
  else return false;
};
