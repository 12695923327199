import classNames from 'classnames';
// import MaterialReactTable, {
//   MaterialReactTableProps,
// } from 'material-react-table';

import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { createTheme, ThemeProvider, useTheme } from '@mui/material';
import { useMemo } from 'react';

export interface TableProps {
  useCallbackClickRow?: any;
  inner?: boolean;
  columns: any;
  data: Record<string, any>[];
  onSelectRow?: any;
  initialState?: object;
  // enableBottomToolbar?: boolean;
  // enableTopToolbar?: boolean;
  enableColumnActions?: boolean;
  enableColumnFilters?: boolean;
  enableSorting?: boolean;
  enableExpanding?: boolean;
  enableExpandAll?: boolean;
  columnFilterDisplayMode: string;
  // enableRowSelection?: boolean;
  // manualPagination?: boolean;
  // manualSorting?: boolean;
}

export function Table(props: any) {
  const {
    useCallbackClickRow,
    enableBottomToolbar = true,
    enableTopToolbar = true,
    enableColumnFilters = false,
    enableColumnActions = false,
    enableSorting = true,
    inner = false,
    onSelectRow,
    enableExpanding,
    enableExpandAll,
    columnFilterDisplayMode = 'custom',
  } = props;

  const table = useMaterialReactTable({
    data: props.data,
    columns: props.columns,
    initialState: props.initialState,
    columnFilterDisplayMode: columnFilterDisplayMode,
    enableBottomToolbar: enableBottomToolbar,
    enableColumnActions: enableColumnActions,
    enableSorting: enableSorting,
    enablePinning: true,
    enableColumnFilters: true,
    enableTopToolbar: true,
    enableExpanding: enableExpanding,
    enableExpandAll: enableExpandAll,
    defaultColumn: {
      minSize: 5,
      maxSize: 9001, //allow columns to get larger than default
      //size: 260, //make columns wider by default
    },
    enableToolbarInternalActions: false,
    positionGlobalFilter: 'left',
    muiBottomToolbarProps: {
      className: classNames({ '!hidden': props.enablePagination === false }),
    },
    muiTopToolbarProps: {
      className:
        '!bg-gray-100 !border-gray-200 border-b-[1px] !min-h-[82px] !p-5',
    },
    muiTablePaperProps: {
      elevation: 0,
      className: classNames(
        '!rounded-lg !border-[1px] !border-gray-200 !overflow-hidden',
        { '!rounded-none !border-none': inner }
      ),
    },
    muiTableHeadCellProps: {
      className:
        '!font-nunito !bg-gray-50 !text-sm !uppercase !text-gray-700 !py-2 !pl-2 !pr-0 !align-middle',
      sx: {
        '.Mui-TableHeadCell-Content-Wrapper': { minWidth: '0px' },
      },
    },
    muiTableBodyRowProps: (data: any) => ({
      onClick: (event: any) => {
        //preventing row click if there other custom component inside cell
        if (
          String(event?.target?.className)?.includes('MuiTableCell-root') &&
          (useCallbackClickRow || onSelectRow)
        ) {
          useCallbackClickRow && useCallbackClickRow(data.row?.original);
          onSelectRow && onSelectRow(data.row?.index);
        }
      },
      className: classNames(
        'relative z-0 hover:z-10 !font-nunito !text-base !text-gray-900 !overflow-visible !pl-2 !pr-0 hover:!outline-primary-900',
        { '!cursor-pointer': useCallbackClickRow || onSelectRow }
      ),
    }),
    muiTableBodyCellProps: (data: any) => ({
      onClick: (event: any) => {
        //preventing row click if there other custom component inside cell
        if (
          String(event?.target?.className)?.includes('MuiTableCell-root') &&
          (useCallbackClickRow || onSelectRow)
        ) {
          useCallbackClickRow && useCallbackClickRow(data.row?.original);
          onSelectRow && onSelectRow(data.row?.index);
        }
      },
      className: classNames(
        '!font-nunito !text-base !text-gray-900 !overflow-visible !pl-2 !pr-0 hover:!outline-primary-900',
        { '!cursor-pointer': useCallbackClickRow || onSelectRow }
      ),
    }),
    ...props,
    muiSearchTextFieldProps: {
      placeholder: 'Search',
      variant: 'outlined',
      margin: 'none',
      className: '!w-[600px] !my-3 bg-white',
      sx: {
        '> .MuiOutlinedInput-root': {
          '& > fieldset': { borderRadius: '10px', backgroundColor: '#fff' },
          '& > input': { padding: '12px 0', backgroundColor: '#fff' },
          '& > svg': { width: '18px', height: '18px' },
        },
      },
      ...(props.muiSearchTextFieldProps || {}),
    },
  });

  const globalTheme = useTheme(); //(optional) if you already have a theme defined in your app root, you can import here

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          primary: {
            main: 'rgb(59 125 112)',
          },
        },
        typography: {
          fontFamily: [
            'Nunito',
            'ui-sans-serif',
            'system-ui',
            '-apple-system',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
          ].join(','),
        },
      }),
    [globalTheme]
  );

  return (
    <ThemeProvider theme={tableTheme}>
      <MaterialReactTable table={table} />
    </ThemeProvider>
  );
}

export default Table;
