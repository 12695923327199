import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { ButtonIcon } from '@rabbit/elements/shared-components';
import { SAGE_ROUTE_NAME } from '@rabbit/config/enums';
import {
  DTVendable,
  DTWarranty_Template,
  PersonaTypeSingleLetter,
} from '@rabbit/data/types';
import placeholderImg from '../../../assets/images/placeholder-image.png';
import {
  formatVendableDataForEditModal,
  generateFilterOptions,
  VendableEditorContext,
} from '@rabbit/bizproc/react';
import { MRT_PaginationState, MRT_SortingState } from 'material-react-table';
import { formatAndGetLastCategory } from 'apps/sage/src/utils/helpers';
import { useTranslation } from 'react-i18next';
import { ModalAddEditItemKind } from '../../organisms/ModalNewEditProduct/ModalAddEditItem';
import { AppContext } from '@rabbit/app-context';
import { useGetMySagePersonas } from '@rabbit/data/portal';
import AgGridTable from '../../AgGridTable';
import { ColDef } from 'ag-grid-community';
import AgGridImageFilter from './CellRender/AgGridImageFilter';

export interface ProductsTabProps {
  handleEditModal: (
    kind: ModalAddEditItemKind,
    title: string,
    itemDetails?: any
  ) => void;
}

interface FilterOptionShape {
  checkboxName: string;
  label: string;
  count: number;
}

interface AppliedFilterShape {
  category: string;
  query: string;
}

const centeredStyle = {
  display: 'flex',
  justifyContent: 'left',
  alignItems: 'center',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
};

export function ProductsTab({ handleEditModal }: ProductsTabProps) {
  /* ---------------------------------- Todos --------------------------------- */
  // TODO: Build the table data using the values in context
  // TODO: DEBOUNCE THE SHIT OUT OF THE TEXT SEARCH - DC
  // Look for the useDebounce hook in the code and replicate that here
  const { config } = useContext(AppContext);
  const contextValues = useContext(VendableEditorContext);
  const {
    vendableList,
    categoryList,
    searchParams,
    changeSearchParams,
    totalHits,
    totalPages,
    getWarrantyTemplates,
    shouldRefresh,
  } = contextValues;
  const { warrantorPersona } = useGetMySagePersonas();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [filterOptions, setFilterOptions] = useState<FilterOptionShape[]>([]);
  const [appliedFilters, setAppliedFilters] = useState<AppliedFilterShape>({
    category: '',
    query: '',
  });
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: searchParams?.hitsPerPage ?? 10,
  });

  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [isResultsFiltered, setIsResultsFiltered] = useState(false);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [warrantyTemplates, setWarrantyTemplates] = useState<
    DTWarranty_Template[]
  >([]);
  const headerClassStyle = 'font-nunito font-bold text-[14px]';

  useEffect(() => {
    getWarrantyTemplates().then((data) => {
      setWarrantyTemplates(data);
    });
  }, []);

  // Format the results
  const formatResultsForProductsTable = (results: DTVendable[]) => {
    const formattedResults = results.map((result, i) => {
      const templateTitle = warrantyTemplates.find(
        (template) => template.docid === result.defaultTemplate
      )?.title;

      return {
        imageUrl: result?.img?.[0] ?? placeholderImg,
        isImageUploaded: !!result?.img?.[0],
        upc: result.upc,
        docid: result.docid,
        productImage: (
          <div
            style={{
              backgroundImage: `url(${result?.img?.[0] ?? placeholderImg})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              width: '75px',
              height: '75px',
              borderRadius: '20px',
              overflow: 'hidden',
            }}
          ></div>
        ),
        lastUpdated:
          result.tupdate || result.tcreate
            ? new Date(result.tupdate ?? result.tcreate)
            : '-',
        productName: result.full,
        sku: result.mpn,
        category:
          result?.category && Array.isArray(result?.category)
            ? formatAndGetLastCategory(result?.category)
            : '-',
        warrantyPlan: templateTitle ?? '-',
        deprecated: result.deprecated ? t('Yes') : t('No'),
        status: result?.published,
        actions: (
          <div className="flex justify-end gap-2 px-2 text-right">
            <PencilSquareIcon
              className="text-primary-900 h-[20px] w-[20px]"
              onClick={() =>
                handleEditModal(
                  ModalAddEditItemKind.products,
                  t('Edit product'),
                  formatVendableDataForEditModal(result)
                )
              }
            />
          </div>
        ),
      };
    });

    return formattedResults;
  };

  useEffect(() => {
    if (vendableList.length) {
      setFilterOptions(generateFilterOptions(categoryList ?? []));
    }
  }, [vendableList.length]);

  useEffect(() => {
    if (searchParams && changeSearchParams)
      changeSearchParams({
        searchQuery: appliedFilters.query,
        page: pagination.pageIndex,
        hitsPerPage: pagination.pageSize,
        //facetFilters: [{ name: 'categories', value: appliedFilters.category }],
      });
  }, [
    appliedFilters.query,
    pagination.pageIndex,
    pagination.pageSize,
    appliedFilters.category,
  ]);

  // if (!vendableList.length)
  //   return <LoadingSpinner size="md" extraClasses="mt-16" />;

  const handleFilterOptions = (filterLabel: string) => {
    setIsResultsFiltered(!isResultsFiltered);
    !isResultsFiltered
      ? setAppliedFilters({ category: filterLabel, query: '' })
      : setAppliedFilters({ category: '', query: '' });
  };

  const handleNavigation = (data: any) => {
    navigate(SAGE_ROUTE_NAME.PRODUCTS + '/' + data.data.docid, {
      state: { docid: data.data.docid, productName: data.data.productName },
    });
  };

  const cellValueRenderer = (value: any) => {
    return (
      <div className="w-full truncate rounded-sm px-2 py-0.5 text-base">
        {value}
      </div>
    );
  };

  const cellImageRenderer = (imageUrl: any, altName: string) => {
    return (
      <div className="h-16 w-16 overflow-hidden rounded-lg border-[1px] border-gray-200 object-cover">
        <img
          src={imageUrl}
          alt={altName}
          className="h-full w-full object-contain"
          width={75}
          height={75}
        />
      </div>
    );
  };

  const columnDefs: ColDef[] = [
    {
      headerName: t('general.productImage'),
      headerClass: headerClassStyle,
      field: 'productImage',
      flex: 1,
      minWidth: 100,
      suppressHeaderFilterButton: true,
      suppressHeaderMenuButton: false,
      cellRenderer: (params: any) =>
        cellImageRenderer(params.data.imageUrl, params.data.productName),
      filter: AgGridImageFilter,
      onCellClicked: handleNavigation,
      cellStyle: { ...centeredStyle },
    },
    {
      headerName: t('general.productName'),
      headerClass: headerClassStyle,
      field: 'productName',
      flex: 1,
      minWidth: 100,
      filter: 'agSetColumnFilter',
      suppressHeaderFilterButton: true,
      suppressHeaderMenuButton: false,
      cellRenderer: (params: any) => cellValueRenderer(params.data.productName),
      onCellClicked: handleNavigation,
      cellStyle: { ...centeredStyle },
    },
    {
      headerName: t('general.category'),
      headerClass: headerClassStyle,
      field: 'category',
      flex: 1,
      minWidth: 100,
      filter: 'agSetColumnFilter',
      suppressHeaderFilterButton: true,
      suppressHeaderMenuButton: false,
      cellRenderer: (params: any) => cellValueRenderer(params.data.category),
      onCellClicked: handleNavigation,
      cellStyle: { ...centeredStyle },
    },
    {
      headerName: t('SKU'),
      headerClass: headerClassStyle,
      field: 'sku',
      flex: 1,
      minWidth: 100,
      filter: 'agSetColumnFilter',
      suppressHeaderFilterButton: true,
      suppressHeaderMenuButton: false,
      editable: true,
      onCellClicked: handleNavigation,
      cellRenderer: (params: any) => cellValueRenderer(params.data.sku),
      cellStyle: { ...centeredStyle },
    },
    {
      headerName: t('general.warrantyPlan'),
      headerClass: headerClassStyle,
      field: 'warrantyPlan',
      flex: 1,
      minWidth: 100,
      filter: 'agSetColumnFilter',
      suppressHeaderFilterButton: true,
      suppressHeaderMenuButton: false,
      onCellClicked: handleNavigation,
      cellRenderer: (params: any) =>
        cellValueRenderer(params.data.warrantyPlan),
      cellStyle: { ...centeredStyle },
    },
    {
      headerName: t('general.deprecated'),
      headerClass: headerClassStyle,
      field: 'deprecated',
      hide: true,
      flex: 1,
      minWidth: 100,
      filter: 'agSetColumnFilter',
      suppressHeaderFilterButton: true,
      suppressHeaderMenuButton: false,
      cellStyle: { ...centeredStyle },
      cellRenderer: (params: any) => cellValueRenderer(params.data.deprecated),
      onCellClicked: handleNavigation,
    },
    {
      headerName: t('general.lastUpdated'),
      headerClass: headerClassStyle,
      field: 'lastUpdated',
      flex: 1,
      minWidth: 100,
      filter: 'agDateColumnFilter',
      suppressHeaderFilterButton: true,
      suppressHeaderMenuButton: false,
      cellStyle: { ...centeredStyle },
      onCellClicked: handleNavigation,
      valueFormatter: (params: any) => {
        if (params.value instanceof Date) {
          const day = params.value.getDate().toString().padStart(2, '0');
          const month = (params.value.getMonth() + 1)
            .toString()
            .padStart(2, '0');
          const year = params.value.getFullYear();
          return `${day}/${month}/${year}`;
        }
        return params.value;
      },
      sort: 'desc',
    },
    {
      headerName: t('general.status'),
      headerClass: headerClassStyle,
      field: 'status',
      flex: 1,
      minWidth: 100,
      filter: 'agSetColumnFilter',
      suppressHeaderFilterButton: true,
      suppressHeaderMenuButton: false,
      onCellClicked: handleNavigation,
      cellRenderer: (params: any) => (
        <div className="flex w-full items-center justify-start rounded-sm py-0.5 text-base">
          {params.data?.status ? (
            <div className="w-auto overflow-hidden rounded bg-green-100 py-1 px-2">
              {t('Published')}
            </div>
          ) : (
            <div className="w-auto overflow-hidden rounded bg-green-100 py-1 px-2">
              {t('Unpublished')}
            </div>
          )}
        </div>
      ),
      cellStyle: { ...centeredStyle },
    },
    {
      headerName: t('general.actions'),
      headerClass: headerClassStyle,
      field: 'actions',
      flex: 1,
      minWidth: 100,
      maxWidth: 125,
      pinned: 'right',
      cellRenderer: (params: any) => (
        <div className="flex w-full items-center justify-start gap-2 text-right">
          <PencilSquareIcon
            className="text-primary-900 h-[25px] w-[25px]"
            onClick={() => {
              handleEditModal(
                ModalAddEditItemKind.products,
                t('Edit product'),
                formatVendableDataForEditModal(params.data)
              );
            }}
          />
        </div>
      ),
      cellStyle: { ...centeredStyle },
      suppressHeaderMenuButton: true,
    },
  ];

  return (
    <div className="flex flex-col">
      <div className="relative z-10 flex w-full items-center justify-end pb-4">
        {config.VENDABLES.EDITOR.CAN_ADD_NEW.includes(
          (warrantorPersona?.personaId?.[0] as PersonaTypeSingleLetter) ?? ''
        ) && (
          <div>
            <ButtonIcon
              label={t('Add product')}
              iconLeft={true}
              onClick={() =>
                handleEditModal(ModalAddEditItemKind.products, t('New product'))
              }
            />
          </div>
        )}
      </div>
      <div className="">
        <AgGridTable
          columnDefs={columnDefs}
          rowData={formatResultsForProductsTable(vendableList)}
          pagination={true}
          sideBar={true}
          domLayout="normal"
          style={{ height: 'calc(100vh - 375px)', width: '100%' }}
          components={{ AgGridImageFilter }}
        />
      </div>
    </div>
  );
}

export default ProductsTab;
