import { useContext } from 'react';
import {
  formatUnixTime,
  LabelledItemShape,
  parseId,
} from '@rabbit/elements/shared-components';
import { CLAIM_STATUS_OPTIONS_LITE } from '@rabbit/bizproc/react';
import { CaseflowContext } from '../../../../../context/CaseflowContext';
import { useTranslation } from 'react-i18next';
import { AppContext } from '@rabbit/app-context';
import { stripTags } from '@rabbit/sage/utils/helpers';

export interface ClaimDetailsSectionDefaultProps {
  renderFns: {
    renderCaseFiles: () => JSX.Element;
    renderShopifyOrderLink: () => JSX.Element;
    renderSerialProof: () => JSX.Element;
    renderConsumerIssueDesc: () => JSX.Element;
    renderClaimDetailItemsLine: (
      claimDetailItems: LabelledItemShape[],
      keys: string[],
      extraClasses?: string
    ) => JSX.Element;
  };
  faultLabels: string[];
}

export function ClaimDetailsSectionDefault(
  props: ClaimDetailsSectionDefaultProps
) {
  const {
    renderCaseFiles,
    renderShopifyOrderLink,
    renderSerialProof,
    renderConsumerIssueDesc,
    renderClaimDetailItemsLine,
  } = props.renderFns;
  const context = useContext(CaseflowContext);
  const { caseId, caseFacts, caseFlowCase, holdingData, caseTimeCreated } =
    context || {};
  const { t } = useTranslation();
  const { config } = useContext(AppContext);

  const claimDetailItems: LabelledItemShape[] = [
    {
      key: 'warranty_status',
      label: t('general.warrantyStatus'),
      value: caseFacts?.warranty_status ?? '-',
    },
    {
      key: 'claim_id',
      label: t('general.claimId'),
      value: caseId
        ? `${
            caseFacts?.warranty_status
              ? caseFacts?.warranty_status.toLowerCase() === 'in warranty'
                ? 'WR-'
                : 'OR-'
              : ''
          }${parseId(caseId)}`
        : '-',
    },
    {
      key: 'claim_status',
      label: t('general.claimStatus'),
      value:
        CLAIM_STATUS_OPTIONS_LITE.find(
          (i) => i.value === caseFlowCase?.GetCaseState()
        )?.label || '-',
    },
    {
      key: 'date_of_claim',
      label: t('general.dateOfClaim'),
      value: caseTimeCreated
        ? formatUnixTime(Number(caseTimeCreated), config.HOLDINGS.DATE_FORMAT)
        : '-',
    },
    {
      key: 'purchase_date',
      label: t('general.purchaseDate'),
      value:
        holdingData?.purchase_time && holdingData?.purchase_time > 0
          ? formatUnixTime(
              holdingData?.purchase_time,
              config.HOLDINGS.DATE_FORMAT
            )
          : '-',
    },
    {
      key: 'customer_issue_type',
      label: t('general.customerIssueType'),
      value: caseFacts?.consumer_issue_type ?? '-',
    },
    {
      key: 'customer_issue_description',
      label: t('general.customerIssueDescription'),
      value: caseFacts?.consumer_issue_description
        ? stripTags(caseFacts?.consumer_issue_description) || ''
        : '-',
    },
    {
      key: 'uploaded_case_files',
      label: t('general.proofOfPurchaseSupportingMaterials'),
      value: renderCaseFiles(),
    },
    {
      key: 'shopify_order',
      label: t('general.orderDetails'),
      value: renderShopifyOrderLink(),
    },
    {
      key: 'serial_number',
      label: t('general.serialNumber'),
      value: caseFacts?.serial_number ?? '-',
    },
    {
      key: 'serial_number_proof',
      label: t('general.serialNumberProof'),
      value: renderSerialProof(),
    },
  ];

  /* -------------------------------- Main tsx -------------------------------- */

  return (
    <div>
      {renderClaimDetailItemsLine(claimDetailItems, [
        'warranty_status',
        'claim_id',
        'date_of_claim',
        'claim_status',
        'purchase_date',
      ])}
      {renderClaimDetailItemsLine(claimDetailItems, [
        'customer_issue_type',
        'customer_issue_description',
        'uploaded_case_files',
        'serial_number',
        'serial_number_proof',
        config.SHOPIFY.ENABLED ? 'shopify_order' : '',
      ])}
    </div>
  );
}

export default ClaimDetailsSectionDefault;
