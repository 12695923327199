import { DR_Business_User_Invite, DR_Tenant_Public } from '@rabbit/data/portal';
import {
  BusinessUserInviteStatus,
  DTBusiness_User_Invite,
  DTTenant_Public,
  FBD_Business_User_Invite,
  FBD_Tenant_Public,
  PersonaTypeSingleLetter,
} from '@rabbit/data/types';
import { useFirestoreQueryData } from '@react-query-firebase/firestore';
import { orderBy, query, where } from 'firebase/firestore';
import { FBD_User_Request } from '../../../../../data/types/src/lib/user-request';

export function useGetSagePartners(tenantid: string) {
  const inviteQuery = query(
    DR_Business_User_Invite.collection(),
    where('status', '!=', BusinessUserInviteStatus.Completed),
    where('tenantLink', '==', tenantid),
    orderBy('status'),
    orderBy('tenantLink')
  );

  const inviteLists = useFirestoreQueryData(
    ['inviteList', tenantid],
    inviteQuery,
    { subscribe: true }
  )?.data?.filter(
    (invite: DTBusiness_User_Invite) =>
      !invite.personas.includes(PersonaTypeSingleLetter.Consumer)
  );

  const partnerTenantQuery = query(
    DR_Tenant_Public.collection(),
    where('isPartnerTo', 'array-contains', tenantid),
    orderBy('isPartnerTo')
  );

  const partnerTenantList = useFirestoreQueryData(
    ['partnerTenantList', tenantid],
    partnerTenantQuery,
    { subscribe: true }
  )?.data?.filter(
    (partnerTenant: DTTenant_Public) =>
      partnerTenant.principals &&
      partnerTenant.principals.length > 0 &&
      !partnerTenant.principals[0].startsWith(PersonaTypeSingleLetter.Consumer)
  );

  const partnersList =
    inviteLists?.map(
      (invite: DTBusiness_User_Invite) =>
        partnerTenantList?.find(
          (partner: DTTenant_Public) => partner.info?.email === invite.email
        ) || invite
    ) || [];

  partnerTenantList?.forEach((partner: DTTenant_Public) => {
    if (
      !partnersList.find(
        (p: DTTenant_Public) => p.info?.email === partner.info?.email
      )
    ) {
      partnersList.unshift(partner);
    }
  });

  const getSingleInvite = async (inviteId: string) => {
    const invite = await FBD_Business_User_Invite.get(inviteId);
    if (!invite) throw new Error('No invite found');
    if (invite.tenantLink !== tenantid)
      throw new Error('You are not authorized to view this invite');
    return invite;
  };

  const getUserRequest = async (partnerId: string) => {
    if (partnerId.includes('_')) {
      const email = partnerId.split('_')[1];
      const userRequest = await FBD_User_Request.query()
        .where('email', '==', email)
        .getContents();
      if (userRequest.length > 0) {
        const businessInvite = await FBD_Business_User_Invite.query()
          .where('email', '==', userRequest[0].email)
          .getContents();
        return { ...businessInvite[0], ...userRequest[0] };
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const getSinglePartner = async (partnerId: string) => {
    const partner = await FBD_Tenant_Public.get(partnerId);

    if (!partner) throw new Error('No partner found');
    if (!partner.isPartnerTo?.includes(tenantid))
      throw new Error('You are not authorized to view this partner');
    return partner;
  };

  return {
    inviteLists,
    partnerTenantList,
    partnersList,
    getSingleInvite,
    getSinglePartner,
    getUserRequest,
  };
}
