import { useContext, useState } from 'react';
import {
  Button,
  CardWrapperWithHeader,
  getStationByKey,
  LoadingSpinner,
  Modal,
  StepsProgress,
} from '@rabbit/elements/shared-components';
import AssignCaseInternalModal from '../../../organisms/case-flow-modals/AssignCaseInternalModal/AssignCaseInternalModal';
import {
  DocumentCheckIcon,
  DocumentIcon,
  InformationCircleIcon,
  ShoppingBagIcon,
  UserIcon,
} from '@heroicons/react/24/solid';
import { CaseflowContext } from '../../../../context/CaseflowContext';
import { AppContext } from '@rabbit/app-context';
import {
  CaseFlowCaseStations,
  CaseflowCaseStatus,
  CaseflowCaseTypes,
  ExternalRepairInvoiceStatus,
} from '@rabbit/bizproc/core';
import ClaimStatusModal from '@rabbit/sage/components/organisms/case-flow-modals/ClaimStatusModal/ClaimStatusModal';
import {
  PersonaIdTypeSplitter,
  PersonaTypeSingleLetter,
} from '@rabbit/data/types';
import { useTranslation } from 'react-i18next';
import { useGetMySagePersonas } from '@rabbit/data/portal';
import ClaimReadinesAssessmentModal, {
  ValidationItem,
  ValidationSection,
} from '@rabbit/sage/components/organisms/case-flow-modals/ClaimReadinessAssismentModal/ClaimReadinessAssismentModal';
import { useNavigate } from 'react-router-dom';
import { SAGE_ROUTE_NAME } from '@rabbit/config/enums';
import { ModalNewEditCustomer } from '@rabbit/sage/components/organisms/ModalNewEditCustomer/ModalNewEditCustomer';
import ModalEditClaimDetails from '@rabbit/sage/components/organisms/ModalEditClaimDetails/ModalEditClaimDetails';
import { ContactCustomerModal } from '@rabbit/sage/components/organisms/case-flow-modals';
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ClaimStatusSectionProps {}

export function ClaimStatusSection(props: ClaimStatusSectionProps) {
  const context = useContext(CaseflowContext);
  const navigate = useNavigate();
  const { config } = useContext(AppContext);
  const [statusModalOpen, setStatusModalOpen] = useState(false);
  const [appointmentModal, setAppointmentModal] = useState(false);
  const [
    claimReadinesAssessmentModalOpen,
    setClaimReadinesAssessmentModalOpen,
  ] = useState(false);
  const { t } = useTranslation();
  const { warrantyDealerPersona } = useGetMySagePersonas();

  const {
    caseState,
    caseFacts,
    setModalSettings,
    setShowModal,
    alterCaseFacts,
    executeAction,
    operatingPersona,
    operatingPersonaSingleKey,
    consumerPersonaData,
    holdingProxyData,
  } = context || {};
  const [editCustomerModal, setEditCustomerModal] = useState(false);
  const [editClaimDetailsModal, setEditClaimDetailsModal] = useState(false);
  const currentStation = getStationByKey(caseState, t);

  const modalSettingsOptions = {
    assign_case: {
      kind: 'generic' as const,
      settings: {
        title: t('Assign case'),
      },
      children: <AssignCaseInternalModal />,
    },
    contact_customer_generic: {
      kind: 'generic' as const,
      settings: {
        title: t('message.contactCustomerForMoreInfo'),
        primaryButtonText: t('general.notifyCustomer'),
        secondaryButtonText: t('general.cancel'),
      },
      children: (
        <ContactCustomerModal
          customeMessage={`<p>Hi Mr ${
            consumerPersonaData?.fullname?.split(' ')[1] ??
            consumerPersonaData?.last_name
          }</p><ul><li>Kindly attach supporting materials for claim.</li></ul>`}
        />
      ),
    },
  };

  const steps = (t: any) => [
    {
      Icon: InformationCircleIcon,
      completed: currentStation.id >= 1 ? true : false,
      title: t('caseFlow.waitingForReview'),
      // content: (
      //   <div className="mt-2 text-xs text-gray-400 font-nunito">
      //     18/04/2023
      //     <br />
      //     9:30 am
      //   </div>
      // ),
    },
    {
      Icon: DocumentCheckIcon,
      completed: currentStation.id >= 2 ? true : false,
      title: t('general.assessment'),
      // content: (
      //   <div className="mt-2 text-xs text-gray-400 font-nunito">
      //     19/04/2023
      //     <br />
      //     01:27 pm
      //   </div>
      // ),
    },
    {
      Icon: InformationCircleIcon,
      completed: currentStation.id >= 6 ? true : false,
      title: t('caseFlow.repairOrReplace'),
      //content: '',
    },
    {
      Icon: DocumentCheckIcon,
      completed: currentStation.id >= 10 ? true : false,
      title: t('caseFlow.complete'),
      //content: '',
    },
  ];

  if (
    !context ||
    !setModalSettings ||
    !setShowModal ||
    !alterCaseFacts ||
    !executeAction ||
    !operatingPersona ||
    !operatingPersonaSingleKey
  )
    return <LoadingSpinner size="xs" />;

  const isRepairer = operatingPersona.startsWith(
    PersonaTypeSingleLetter.Repairer + PersonaIdTypeSplitter
  );

  const isConsumer = operatingPersona.startsWith(
    PersonaTypeSingleLetter.Consumer + PersonaIdTypeSplitter
  );

  let hideStatus = false;
  if (isRepairer) {
    if (config.CLAIMS.CASEFLOW_TYPE === CaseflowCaseTypes.FATBIKES) {
      hideStatus = true;
      return;
    }
    if (!caseFacts?.external_repair_invoices) return;
    const invoiceNotApproved =
      (
        caseFacts?.external_repair_invoices
          ?.map((invoice) => {
            return invoice?.status !== ExternalRepairInvoiceStatus.APPROVED;
          })
          .filter((i) => i) || []
      )?.length > 0;

    if (invoiceNotApproved) {
      hideStatus = true;
    } else if (caseFacts?.final_assessment === 'Not covered') {
      hideStatus = true;
    }
  }
  if (isConsumer) {
    hideStatus = true;
  }

  const onProductDetailsClick = () => {
    navigate(SAGE_ROUTE_NAME.REGISTRATIONS + '/' + holdingProxyData?.docid, {
      state: 'off',
    });
  };

  // Static validation data inside the component
  const customerValidation: ValidationItem[] = [
    { message: t('Customer Details submitted.'), isValid: true },
    // { message: t('general.emailValid'), isValid: true },
  ];

  const geoLocationValidation: ValidationItem[] = [
    {
      message: t(
        'Weather - greater than expected - winds > 145 mlles per  hour'
      ),
      isValid: false,
    },
  ];

  const claimValidation: ValidationItem[] = [
    { message: t('Supporting materials'), isValid: false },
  ];

  const sections: ValidationSection[] = [
    {
      name: t('general.customerDetails'),
      icon: <UserIcon className="h-6 w-6" />,
      items: customerValidation,
      sectionId: 'customer-section',
      actionButtonLabel: t('Request customer to update'),
      actionButtonClick: () => {
        setClaimReadinesAssessmentModalOpen(false);
      },
    },
    {
      name: t('general.claimDetails'),
      icon: <DocumentIcon className="h-6 w-6" />,
      items: claimValidation,
      sectionId: 'claim-section',
      actionButtonLabel: t('Request missing information'),
      actionButtonClick: () => {
        setModalSettings(modalSettingsOptions.contact_customer_generic);
        setShowModal(true);
        setClaimReadinesAssessmentModalOpen(false);
      },
    },
    {
      name: t('Geo Location Details'),
      icon: <ShoppingBagIcon className="h-6 w-6" />,
      items: geoLocationValidation,
      sectionId: 'product-section',
      actionButtonLabel: t('View information'),
      actionButtonClick: () => {
        // onProductDetailsClick();
        setClaimReadinesAssessmentModalOpen(false);
        window.open('https://www.nhc.noaa.gov/cyclones/');
      },
    },
  ];

  return (
    <CardWrapperWithHeader
      title={t('general.claimStatus')}
      headerRight={
        <div className="flex items-center gap-5">
          <Button
            size="sm"
            kind="primary"
            children={t('Book an appointment')}
            onClick={() => setAppointmentModal(true)}
          />
          {config.CLAIMS.CLAIM_DETAIL_VIEW.CLAIM_READINESS_ASSESSMENT && (
            <Button
              className="!px-12"
              size="sm"
              kind="primary"
              children={t('Ask Owen AI')}
              onClick={() => setClaimReadinesAssessmentModalOpen(true)}
            />
          )}
          {(config.CLAIMS.CASEFLOW_TYPE === CaseflowCaseTypes.LITE ||
            config.CLAIMS.CASEFLOW_TYPE === CaseflowCaseTypes.FATBIKES) &&
            !hideStatus &&
            !warrantyDealerPersona && (
              <Button
                size="sm"
                kind="outline"
                children={t('general.updateStatus')}
                onClick={() => setStatusModalOpen(true)}
              />
            )}
          {config.CLAIMS.CLAIMS_FLOW.CAN_ASSIGN_REPAIRER.includes(
            operatingPersonaSingleKey
          ) && (
            <Button
              size="sm"
              kind="primary"
              children={`${
                caseFacts?.delegate_repairer_name !== 'Unassigned'
                  ? t('general.assignedTo') +
                    ': ' +
                    caseFacts?.delegate_repairer_name
                  : t('general.unassigned')
              }`}
              onClick={() => {
                setModalSettings(modalSettingsOptions.assign_case);
                setShowModal(true);
              }}
              // TODO: handle this better without caseflow type dependency
              disabled={
                config.CLAIMS.CASEFLOW_TYPE === CaseflowCaseTypes.FATBIKES &&
                caseState !== CaseFlowCaseStations.PRELIMINARY_ASSESSMENT
              }
            />
          )}
        </div>
      }
    >
      <div className="px-10">
        <StepsProgress steps={steps(t)} />
      </div>
      {statusModalOpen && (
        <ClaimStatusModal onClose={() => setStatusModalOpen(false)} />
      )}
      {claimReadinesAssessmentModalOpen && (
        <ClaimReadinesAssessmentModal
          sections={sections}
          onClose={() => setClaimReadinesAssessmentModalOpen(false)}
        />
      )}
      {editCustomerModal && (
        <div>
          <ModalNewEditCustomer
            handleClose={() => setEditCustomerModal(false)}
            handleDelete={() => {}}
            customerDetails={consumerPersonaData}
            modalSettings={{
              title: t('general.editCustomer'),
              handleClose: () => setEditCustomerModal(false),
            }}
          />
        </div>
      )}

      {editClaimDetailsModal && (
        <ModalEditClaimDetails
          modalSettings={{
            title: t('general.editOrUpdateClaimDetails'),
            handleClose: () => setEditClaimDetailsModal(false),
          }}
          claimDetails={caseFacts}
          handleClose={() => setEditClaimDetailsModal(false)}
        />
      )}
      {appointmentModal && (
        <Modal
          kind="generic"
          settings={{
            title: t('Book an appointment'),
            handleClose: () => setAppointmentModal(false),
          }}
          className="m-auto h-[724px] w-[1024px] overflow-hidden rounded-md border bg-white"
        >
          <div className="px-5">
            <iframe
              className="h-[614px] w-full"
              src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ0BKdHk9tWO4bYitvl0U51-AXhaIYEuqx3_fJUQh9-hAok9I9HiT9bGYzmbxQt9hiNSilQz-ZPw?gv=true"
            ></iframe>
          </div>
        </Modal>
      )}
    </CardWrapperWithHeader>
  );
}

export default ClaimStatusSection;
