import { ClipboardDocumentIcon } from '@heroicons/react/24/outline';
import {
  CaseflowCaseTypes,
  ExternalRepairInvoiceStatus,
} from '@rabbit/bizproc/core';
import { useManageFaults } from '@rabbit/bizproc/react';
import { AppContext } from '@rabbit/app-context';
import {
  PersonaIdTypeSplitter,
  PersonaTypeSingleLetter,
} from '@rabbit/data/types';
import {
  Button,
  CardWrapperWithHeader,
  LabelledItemList,
  LabelledItemShape,
} from '@rabbit/elements/shared-components';
import UpdateClaimAssessmentModal from '@rabbit/sage/components/organisms/case-flow-modals/UpdateClaimAssessmentModal/UpdateClaimAssessmentModal';
import { CaseflowContext } from '@rabbit/sage/context/CaseflowContext';
import { stripTags } from '@rabbit/sage/utils/helpers';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetMySagePersonas } from '@rabbit/data/portal';

export default function ClaimAssessmentSection() {
  const { holdingData, caseFacts, operatingPersona } =
    useContext(CaseflowContext) || {};
  const { t } = useTranslation();
  const { warrantyDealerPersona } = useGetMySagePersonas();
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const { faultList } = useManageFaults();
  const faultOptions = !faultList?.data
    ? []
    : faultList.data.map((fault) => ({
        id: fault.docid,
        value: fault.docid,
        label: fault.title,
        name: fault.title,
      }));

  const items: any[] = [];
  caseFacts?.repair_work_registry?.forEach((item) => {
    items.push(...item.parts_used_for_repair);
  });
  const parts = caseFacts?.assessment_parts?.map((part) => part.name);

  const record: LabelledItemShape[] = [
    {
      label: t('general.parts'),
      value: parts?.join(', ') || '-',
    },
    {
      label: t('general.faults'),
      value:
        caseFacts?.holding_faults
          ?.map((id) => faultOptions.find((f) => f.id === id)?.label)
          .join(', ') || '-',
    },
    {
      label: t('general.preliminaryAssessment'),
      value: caseFacts?.preliminary_assessment || '-',
    },
    {
      label: t('general.finalAssessment'),
      value: caseFacts?.final_assessment || '-',
    },
    {
      label: t('general.outcome'),
      value: caseFacts?.claim_outcome || '-',
    },
    {
      label: t('general.cause'),
      value: caseFacts?.claim_cause || '-',
    },
    {
      label: t('general.additionalComment'),
      value: stripTags(caseFacts?.assessment_comment || '') || '-',
    },
  ];

  const isRepairer = operatingPersona?.startsWith(
    PersonaTypeSingleLetter.Repairer + PersonaIdTypeSplitter
  );

  let hideAssessment = false;
  if (isRepairer) {
    if (!caseFacts?.external_repair_invoices) return;
    const invoiceNotApproved =
      (
        caseFacts?.external_repair_invoices
          ?.map((invoice) => {
            return invoice?.status !== ExternalRepairInvoiceStatus.APPROVED;
          })
          .filter((i) => i) || []
      )?.length > 0;

    if (invoiceNotApproved) {
      hideAssessment = true;
    } else if (caseFacts?.final_assessment === 'Not covered') {
      hideAssessment = true;
    }
  }

  return (
    <CardWrapperWithHeader
      title={t('general.claimAssessment')}
      headerRight={
        !hideAssessment &&
        !warrantyDealerPersona && (
          <Button
            kind="primary"
            size="sm"
            onClick={() => setShowUpdateModal(true)}
            icon={<ClipboardDocumentIcon />}
            children={t('general.updateAssessment')}
          />
        )
      }
    >
      <LabelledItemList
        items={record}
        itemProps={{
          labelClasses: 'font-nunito mb-[10px] text-sm text-gray-500',
          valueClasses: 'font-nunito font-medium',
        }}
      />
      {showUpdateModal && (
        <UpdateClaimAssessmentModal onClose={() => setShowUpdateModal(false)} />
      )}
    </CardWrapperWithHeader>
  );
}
