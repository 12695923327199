import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import {
  Button,
  CardWrapperWithHeader,
  LabelledItemList,
  LabelledItemShape,
  Modal,
  formatUnixTime,
  getCurrencyFormat,
  getFormattedDate,
  renderOrderDetails,
} from '@rabbit/elements/shared-components';
import {
  formatDate,
  getVehicleCategoryByValue,
} from '../../../../utils/helpers';
import {
  DTConsumer_Private,
  DTHoldingProxy,
  DTHolding_Manufacturer,
  DTHolding_Private,
  DTHolding_Public,
  TableCover,
  UserUploadedDocument,
  VehicleInfo,
  Warranty,
  DTVendable,
} from '@rabbit/data/types';
import { SetStateAction, useState, useContext, useEffect } from 'react';
import { useConsumerHoldingEditor } from '@rabbit/bizproc/react';
import Skeleton from 'react-loading-skeleton';
import {
  DocTypeShapeTypes,
  UploadedFileCategories,
} from '@rabbit/elements/shared-types';
import { SageFileUploader } from '@rabbit/sage/components/organisms/upload-wrapper/SageFileUploader';
import { useTranslation } from 'react-i18next';
import { BL_Warranty } from '@rabbit/bizproc/core';
import { REGISTRATION_FIELDS } from '@rabbit/config/enums';
import { BL_HOLDING } from '../../../../../../../libs/bizproc/core/src/logic/holding.ts';
import { AppContext } from '@rabbit/app-context';

interface RegistrationsHoldingDetailsSectionProps {
  allHoldingsList?: DTHoldingProxy[] | null;
  consumer_private?: DTConsumer_Private | null;
  holding_proxy?: DTHoldingProxy | null;
  holdingId: string;
  storeAddress: string;
}
export default function RegistrationsHoldingDetailsSection({
  allHoldingsList,
  holding_proxy,
  consumer_private,
  holdingId,
  storeAddress,
}: RegistrationsHoldingDetailsSectionProps) {
  const [proofOfPurchaseModal, setProofOfPurchaseModal] =
    useState<boolean>(false);
  const [proofOfSerialNumberModal, setProofOfSerialNumberModal] =
    useState<boolean>(false);

  const [vendableManualModal, setVendableManualModal] =
    useState<boolean>(false);

  const [vendable, setVendable] = useState<DTVendable>();
  const [vendableLoaded, setVendableLoaded] = useState<boolean>(false);

  // Ideally this hook wouldn't be necessary, but it currently is used to provide real time updates when a proof of purchase is updated
  // Will remove later - DC
  const { body } = useConsumerHoldingEditor(
    consumer_private?.docid || '',
    holdingId.split('_')[0]
  );
  const { t } = useTranslation();

  const warranty = BL_Warranty.getLatestWarranty(holding_proxy?.warranties);

  useEffect(() => {
    (async () => {
      if (!vendableLoaded) {
        setVendableLoaded(true);
        setVendable(await BL_HOLDING.getVendable(holding_proxy?.vendable!));
      }
    })();
  }, [holding_proxy?.vendable, vendableLoaded, setVendableLoaded]);

  const renderDetails = () => {
    if (holding_proxy && allHoldingsList) {
      switch (t('tenantLink')) {
        case 'WARRANTYIRELAND':
          return renderWarrantyIrelandDetails(
            holding_proxy,
            setProofOfPurchaseModal,
            body,
            holdingId,
            allHoldingsList
          );
        case 'NUCOVER':
          return renderNuCoverDetails(
            holding_proxy,
            setProofOfPurchaseModal,
            body,
            holdingId,
            allHoldingsList,
            storeAddress
          );
        case 'PINNACLEWARRANTIES':
          return renderPinnacleWarrantiesDetails(holding_proxy);
        default:
          return renderDefaultDetails(
            holding_proxy,
            storeAddress,
            setProofOfPurchaseModal,
            setProofOfSerialNumberModal,
            setVendableManualModal,
            body,
            holdingId,
            allHoldingsList,
            warranty,
            vendable
          );
      }
    }
  };

  return (
    <>
      <CardWrapperWithHeader title={t('general.purchaseDetails')}>
        {!holding_proxy || !allHoldingsList ? (
          <div>
            <div className="font-nunito mb-[30px] grid grid-cols-2 gap-8 md:grid-cols-5 lg:grid-cols-5">
              <div className="mb-10">
                {' '}
                <Skeleton count={2} />
              </div>
              <div className="mb-10">
                {' '}
                <Skeleton count={2} />
              </div>
              <div className="mb-10">
                {' '}
                <Skeleton count={2} />
              </div>
              <div className="mb-10">
                {' '}
                <Skeleton count={2} />
              </div>
              <div className="mb-10">
                {' '}
                <Skeleton count={2} />
              </div>
            </div>
            <div className="font-nunito grid grid-cols-2 gap-8 md:grid-cols-5 lg:grid-cols-5">
              <div className="mb-10">
                {' '}
                <Skeleton count={2} />
              </div>
              <div className="mb-10">
                {' '}
                <Skeleton count={2} />
              </div>
              <div className="mb-10">
                {' '}
                <Skeleton count={2} />
              </div>
              <div className="mb-10">
                {' '}
                <Skeleton count={2} />
              </div>
              <div className="mb-10">
                {' '}
                <Skeleton count={2} />
              </div>
            </div>

            <div className="font-nunito grid grid-cols-2 gap-8 md:grid-cols-5 lg:grid-cols-5">
              <div className="mb-10">
                {' '}
                <Skeleton count={2} />
              </div>
              <div className="mb-10">
                {' '}
                <Skeleton count={2} />
              </div>
              <div className="mb-10">
                {' '}
                <Skeleton count={2} />
              </div>
            </div>
          </div>
        ) : (
          <div className="font-nunito">{renderDetails()}</div>
        )}
      </CardWrapperWithHeader>
      {proofOfPurchaseModal && (
        <Modal
          kind="generic"
          settings={{
            title: t('general.proofOfPurchase'),
            handleClose: () => setProofOfPurchaseModal(false),
          }}
          className="m-auto w-[724px] rounded-md border bg-white"
        >
          <div className="px-5">
            <SageFileUploader
              label={t('general.proofOfPurchase')}
              identifiers={{
                category: UploadedFileCategories.ConsumerProofPurchase,
                docType: {
                  docid: holdingId.split('_')[0],
                  type: DocTypeShapeTypes.Case,
                },
                personaId: consumer_private?.docid ?? '',
              }}
              //alterCaseFacts={alterCaseFacts} //No need - it's working without it
              accepts={['image/*', '.pdf']}
              //onUploadCompleted={onProofOfPurchaseUpdated} //No need - it's working without it
              currentFiles={body?.private?.receipt ?? []}
              shouldAutoUpdateDocs={true}
            />
            <div className="mt-4 flex w-full gap-2">
              <Button
                kind="primary"
                onClick={() => setProofOfPurchaseModal(false)}
              >
                {t('OK')}
              </Button>
              <Button kind="red" onClick={() => setProofOfPurchaseModal(false)}>
                {t('Close')}
              </Button>
            </div>
          </div>
        </Modal>
      )}
      {proofOfSerialNumberModal && (
        <Modal
          kind="generic"
          settings={{
            title: t('general.proofOfSerialNumber'),
            handleClose: () => setProofOfSerialNumberModal(false),
          }}
          className="m-auto w-[724px] rounded-md border bg-white"
        >
          <div className="px-5">
            <SageFileUploader
              label={t('general.proofOfSerialNumber')}
              identifiers={{
                category: UploadedFileCategories.SerialNumberProof,
                docType: {
                  docid: holdingId.split('_')[0],
                  type: DocTypeShapeTypes.Case,
                },
                personaId: consumer_private?.docid ?? '',
              }}
              accepts={['image/*', '.pdf']}
              currentFiles={body?.private?.serial_proof ?? []}
              shouldAutoUpdateDocs={true}
            />
            <div className="mt-4 flex w-full gap-2">
              <Button
                kind="primary"
                onClick={() => setProofOfSerialNumberModal(false)}
              >
                {t('OK')}
              </Button>
              <Button
                kind="red"
                onClick={() => setProofOfSerialNumberModal(false)}
              >
                {t('Close')}
              </Button>
            </div>
          </div>
        </Modal>
      )}
      {vendableManualModal && (
        <Modal
          kind="generic"
          settings={{
            title: t('general.productDocumentation'),
            handleClose: () => setVendableManualModal(false),
          }}
          className="m-auto w-[724px] rounded-md border bg-white"
        >
          <div className="px-5">
            <SageFileUploader
              label={t('general.productDocumentation')}
              identifiers={{
                category: UploadedFileCategories.VendableManuals,
                docType: {
                  docid: holdingId.split('_')[0],
                  type: DocTypeShapeTypes.Case,
                },
                personaId: consumer_private?.docid ?? '',
              }}
              disabled={true}
              accepts={['image/*', '.pdf']}
              currentFiles={vendable?.manual ?? []}
              shouldAutoUpdateDocs={true}
            />
            <div className="mt-4 flex w-full gap-2">
              <Button
                kind="primary"
                onClick={() => setVendableManualModal(false)}
              >
                {t('OK')}
              </Button>
              <Button kind="red" onClick={() => setVendableManualModal(false)}>
                {t('Close')}
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}

/* -------------------------------------------------------------------------- */
/*                                   Helpers                                  */
/* -------------------------------------------------------------------------- */

/* ----------------------------- Render helpers ----------------------------- */

const renderDefaultDetails = (
  holding_proxy: DTHoldingProxy,
  storeAddress: string,
  setProofOfPurchaseModal: {
    (value: SetStateAction<boolean>): void;
    (value: SetStateAction<boolean>): void;
  },
  setProofOfSerialNumberModal: (value: SetStateAction<boolean>) => void,
  setVendableManualModal: (value: SetStateAction<boolean>) => void,
  body: {
    public: DTHolding_Public | null;
    private: DTHolding_Private | null;
    manufacturer: DTHolding_Manufacturer | null;
  },
  holdingId: string,
  allHoldingsList: DTHoldingProxy[],
  warranty: Warranty | undefined,
  vendable: DTVendable | undefined
) => {
  const { t } = useTranslation();
  const { config } = useContext(AppContext) || {};
  const CFG_SHOPIFY_URL = t('CFG_SHOPIFY_URL');

  const isShopifyTenant =
    CFG_SHOPIFY_URL &&
    holding_proxy?.shopifyLinks?.shopId &&
    holding_proxy?.shopifyLinks?.orderId;
  const [claimFilesModal, setClaimFilesModal] = useState(false);
  const handleClaimFilesClick = () => {
    setClaimFilesModal(true);
  };

  const items: LabelledItemShape[] = [
    {
      label: t('general.productId'),
      value: holding_proxy.holdingLink.slice(0, 5).toUpperCase(),
    },
    {
      label: t('general.productName'),
      value: holding_proxy.title,
    },
    {
      label: t('general.productCategory'),
      value: Array.isArray(holding_proxy.category)
        ? holding_proxy.category.join(', ')
        : '-',
    },
    {
      label: t('general.modelNumber'),
      value:
        holding_proxy.mpn && holding_proxy.mpn !== 'n/a'
          ? holding_proxy.mpn
          : '-',
    },
    ...(config.HOLDINGS.NEW_REGISTRATION_FLOW.EXTRA_FIELDS.includes(
      REGISTRATION_FIELDS.CHASSIS_NUMBER
    )
      ? [
          {
            label: t('general.chassis_number'),
            value: holding_proxy.chassis_number ?? '-',
          },
        ]
      : []),

    ...(config.HOLDINGS.NEW_REGISTRATION_FLOW.EXTRA_FIELDS.includes(
      REGISTRATION_FIELDS.REGISTRATION_NUMBER
    )
      ? [
          {
            label: t('general.registration_number'),
            value: holding_proxy.registration_number ?? '-',
          },
        ]
      : []),

    ...(config.HOLDINGS.NEW_REGISTRATION_FLOW.EXTRA_FIELDS.includes(
      REGISTRATION_FIELDS.MILEAGE
    )
      ? [
          {
            label: t('general.mileage'),
            value: `${
              holding_proxy?.mileage?.value
                ? Number(holding_proxy?.mileage?.value).toLocaleString('en')
                : '0'
            } ${holding_proxy?.mileage?.unit ?? ''}`,
          },
        ]
      : []),
    ...(isShopifyTenant
      ? [
          {
            label: t('general.orderDetails'),
            value: renderOrderDetails(
              isShopifyTenant ?? false,
              holding_proxy,
              handleClaimFilesClick
            ),
          },
        ]
      : []),
    ...(config.HOLDINGS.REGISTRATION_DETAILS_VIEW.SERIAL_NUMBER
      ? [
          {
            label: t('general.serialNumber'),
            value: holding_proxy?.serial === '' ? '-' : holding_proxy?.serial,
          },
        ]
      : []),

    {
      label: t('general.store'),
      value: storeAddress || '-',
    },
    ...(config.HOLDINGS.NEW_REGISTRATION_FLOW.EXTRA_FIELDS.includes(
      REGISTRATION_FIELDS.PRODUCTION_DATE
    )
      ? [
          {
            label: t('general.production_date'),
            value: holding_proxy.production_date
              ? formatDate(new Date(holding_proxy.production_date))
              : '-',
          },
        ]
      : []),
    {
      label: t('general.purchaseDate'),
      value:
        holding_proxy.purchase_time && holding_proxy.purchase_time > 0
          ? formatUnixTime(
              holding_proxy.purchase_time,
              config.HOLDINGS.DATE_FORMAT
            )
          : '-',
    },
    {
      label: t('general.registrationDate'),
      value:
        holding_proxy.register_time && holding_proxy.register_time > 0
          ? formatUnixTime(
              holding_proxy.register_time,
              config.HOLDINGS.DATE_FORMAT
            )
          : '-',
    },
    ...(config.HOLDINGS.REGISTRATION_DETAILS_VIEW.PROOF_OF_PURCHASE
      ? [
          {
            label: t('general.proofOfPurchase'),
            value: renderPoP(setProofOfPurchaseModal, body?.private?.receipt),
          },
        ]
      : []),

    ...(config.HOLDINGS.REGISTRATION_DETAILS_VIEW.PRODUCT_DOCUMENTATION
      ? [
          {
            label: t('general.productDocumentation'),
            value: renderPoPManual(setVendableManualModal, vendable?.manual),
          },
        ]
      : []),
  ];

  if (config?.CLAIMS.CLAIMS_FLOW.SERIAL_NUMBER_PROOF)
    items.push({
      label: t('general.proofOfSerialNumber'),
      value: renderPoP(
        setProofOfSerialNumberModal,
        body?.private?.serial_proof
      ),
    });
  if (config?.HOLDINGS.REGISTRATION_DETAILS_VIEW.PURCHASE_PRICE)
    items.push(
      ...[
        {
          label: t('general.purchasePrice'),
          value:
            holding_proxy.purchase_price &&
            holding_proxy.purchase_price.amount !== 0 &&
            holding_proxy.purchase_price.currency !== '-'
              ? getCurrencyFormat(
                  holding_proxy.purchase_price.amount,
                  holding_proxy.purchase_price.currency
                )
              : '-',
        },
      ]
    );
  return (
    <>
      <LabelledItemList items={items} />
    </>
  );
};

const renderWarrantyIrelandDetails = (
  holding_proxy: DTHoldingProxy,
  setProofOfPurchaseModal: {
    (value: SetStateAction<boolean>): void;
    (value: SetStateAction<boolean>): void;
  },
  body: {
    public: DTHolding_Public | null;
    private: DTHolding_Private | null;
    manufacturer: DTHolding_Manufacturer | null;
  },
  holdingId: string,
  allHoldingsList: DTHoldingProxy[]
) => {
  const productInfo = holding_proxy.self_registration?.srvInfo
    ?.productInfo as VehicleInfo;
  const { t } = useTranslation();
  const { tenantInfo } = useContext(AppContext);

  const items: LabelledItemShape[] = [
    {
      label: t('general.vehicleId'),
      value: holding_proxy.holdingLink.slice(0, 5).toUpperCase(),
    },
    {
      label: t('general.registrationNo'),
      value: productInfo?.cherishedRegistrationNo?.trim() || '-',
    },
    {
      label: t('Cherished plate'),
      value: productInfo?.cherishedRegistrationNo || '-',
    },
    {
      label: t('general.make'),
      value: holding_proxy.self_registration?.brand || '-',
    },
    { label: t('general.model'), value: productInfo?.model || '-' },
    {
      label: t('general.currentMileage'),
      value: productInfo?.mileage
        ? productInfo.mileage + ' ' + productInfo.mileageUnit
        : '-',
    },
    { label: t('general.vin'), value: productInfo?.chassisNo || '-' },
    { label: t('general.store'), value: tenantInfo?.name || '-' },
    {
      label: t('general.productCategory'),
      value: holding_proxy.self_registration?.srvInfo?.type
        ? holding_proxy.self_registration.srvInfo.type.charAt(0).toUpperCase() +
          holding_proxy.self_registration.srvInfo.type.slice(1)
        : '-',
    },
    {
      label: t('general.purchaseDate'),
      value:
        holding_proxy?.purchase_time > 0
          ? getFormattedDate(holding_proxy.purchase_time)
          : '-',
    },
    {
      label: t('general.firstRegistrationDate'),
      value: productInfo?.regDate ? getFormattedDate(productInfo.regDate) : '-',
    },
    {
      label: t('general.yearOfManufacture'),
      value: productInfo?.yearOfManufacture
        ? (productInfo?.yearOfManufacture as any)
        : '-',
    },
    {
      label: t('general.nctDate'),
      value:
        productInfo?.techCheckDate && productInfo.techCheckDate !== '0000-00-00'
          ? productInfo.techCheckDate
          : '-',
    },
    {
      label: t('general.purchasePrice'),
      value: holding_proxy.purchase_price?.amount
        ? getCurrencyFormat(
            holding_proxy.purchase_price.amount,
            holding_proxy.purchase_price.currency
          )
        : '-',
    },
    {
      label: t('general.registrationType'),
      value: productInfo?.isManuallyRegistered ? t('Manual') : t('VRM'),
    },
  ];

  return (
    <>
      <LabelledItemList items={items} />
    </>
  );
};

const renderNuCoverDetails = (
  holding_proxy: DTHoldingProxy,
  setProofOfPurchaseModal: {
    (value: SetStateAction<boolean>): void;
    (value: SetStateAction<boolean>): void;
  },
  body: {
    public: DTHolding_Public | null;
    private: DTHolding_Private | null;
    manufacturer: DTHolding_Manufacturer | null;
  },
  holdingId: string,
  allHoldingsList: DTHoldingProxy[],
  storeAddress: string
) => {
  const { t } = useTranslation();
  const { tenantInfo } = useContext(AppContext);
  const store = holding_proxy.self_registration?.brand || storeAddress;
  const items: LabelledItemShape[] = [
    {
      label: t('general.productId'),
      value: holding_proxy.holdingLink.slice(0, 5).toUpperCase(),
    },
    {
      label: t('general.productName'),
      value:
        (holding_proxy.self_registration?.srvInfo?.productInfo as TableCover)
          ?.typeofStone || '-',
    },
    { label: t('general.store'), value: store || tenantInfo?.name || '-' },
    { label: t('general.productCategory'), value: 'Stones' },
    {
      label: t('general.purchaseDate'),
      value:
        holding_proxy.purchase_time > 0
          ? formatUnixTime(holding_proxy.purchase_time, 'dd/MM/yyyy')
          : '-',
    },
    {
      label: t('general.registrationDate'),
      value:
        holding_proxy.register_time > 0
          ? formatUnixTime(holding_proxy.register_time, 'dd/MM/yyyy')
          : '-',
    },
    {
      label: t('general.proofOfPurchase'),
      value: renderPoP(setProofOfPurchaseModal, body?.private?.receipt) || '-',
    },
  ];
  return (
    <>
      <LabelledItemList items={items} />
    </>
  );
};

const renderPinnacleWarrantiesDetails = (holding_proxy: DTHoldingProxy) => {
  const productInfo = holding_proxy.self_registration?.srvInfo
    ?.productInfo as VehicleInfo;
  const { t } = useTranslation();
  const { tenantInfo } = useContext(AppContext);

  const items: LabelledItemShape[] = [
    {
      label: t('general.vehicleId'),
      value: holding_proxy.holdingLink.slice(0, 5).toUpperCase(),
    },
    {
      label: t('general.registrationNo'),
      value: productInfo.registrationNo || '-',
    },
  ];

  if (
    productInfo?.cherishedRegistrationNo &&
    productInfo?.cherishedRegistrationNo?.trim() !== ''
  ) {
    items.push({
      label: t('Cherished plate'),
      value: productInfo?.cherishedRegistrationNo,
    });
  }

  items.push(
    ...[
      {
        label: t('general.make'),
        value: holding_proxy.self_registration?.brand || '-',
      },
      { label: t('general.model'), value: productInfo?.model || '-' },
      {
        label: t('general.mileage'),
        value:
          (productInfo?.mileage &&
            productInfo.mileage + ' ' + productInfo.mileageUnit) ||
          '-',
      },
      { label: t('general.vin'), value: productInfo?.chassisNo || '-' },
      { label: t('general.driveType'), value: productInfo?.driveType || '-' },
      {
        label: t('general.vehicleCategory'),
        value:
          getVehicleCategoryByValue(productInfo?.vehicleCategory || '')
            ?.label || '-',
      },
      {
        label: t('general.commercialVehicle'),
        value: productInfo?.isCommercial ? 'Yes' : 'No',
      },
      { label: t('general.store'), value: tenantInfo?.name || '-' },
      {
        label: t('general.productCategory'),
        value: holding_proxy.self_registration?.srvInfo?.type
          ? holding_proxy.self_registration.srvInfo.type
              .charAt(0)
              .toUpperCase() +
            holding_proxy.self_registration.srvInfo.type.slice(1)
          : '-',
      },
      {
        label: t('general.purchaseDate'),
        value:
          holding_proxy.purchase_time > 0
            ? getFormattedDate(holding_proxy.purchase_time)
            : '-',
      },
      {
        label: t('general.firstRegistrationDate'),
        value: productInfo?.regDate
          ? getFormattedDate(productInfo.regDate)
          : '-',
      },
      {
        label: t('general.yearOfManufacture'),
        value: productInfo?.yearOfManufacture
          ? (productInfo?.yearOfManufacture as any)
          : '-',
      },
      {
        label: t('general.lastServiceDate'),
        value:
          productInfo?.lastServiceDate &&
          productInfo.lastServiceDate !== '0000-00-00'
            ? productInfo.lastServiceDate
            : '-',
      },
      {
        label: t('general.motDateExpiration'),
        value:
          productInfo?.techCheckDate &&
          productInfo.techCheckDate !== '0000-00-00'
            ? productInfo.techCheckDate
            : '-',
      },
      {
        label: t('general.purchasePrice'),
        value: holding_proxy.purchase_price?.amount
          ? getCurrencyFormat(
              holding_proxy.purchase_price.amount,
              holding_proxy.purchase_price.currency
            )
          : '-',
      },
      {
        label: t('general.registrationType'),
        value: productInfo?.isManuallyRegistered ? t('Manual') : t('VRM'),
      },
    ]
  );

  return (
    <>
      <LabelledItemList items={items} />
    </>
  );
};

const renderPoP = (
  setProofOfPurchaseModal: React.Dispatch<React.SetStateAction<boolean>>,
  PoP?: UserUploadedDocument[]
) => {
  const totalFiles = PoP?.length ?? 0;
  return (
    <div
      className={
        'flex cursor-pointer items-center gap-2 ' +
        (totalFiles === 0 ? 'text-red-500' : 'text-black')
      }
      onClick={() => setProofOfPurchaseModal(true)}
    >
      {totalFiles} file(s) uploaded{' '}
      <div>
        <ArrowTopRightOnSquareIcon className="h-4 w-4 text-black" />
      </div>
    </div>
  );
};
const renderPoPManual = (
  setVendableManualModal: React.Dispatch<React.SetStateAction<boolean>>,
  PoP?: UserUploadedDocument[]
) => {
  const totalFiles = PoP?.length ?? 0;
  return (
    <div
      className={
        'flex cursor-pointer items-center gap-2 ' +
        (totalFiles === 0 ? 'text-red-500' : 'text-black')
      }
      onClick={() => setVendableManualModal(true)}
    >
      {totalFiles} file(s) uploaded{' '}
      <div>
        <ArrowTopRightOnSquareIcon className="h-4 w-4 text-black" />
      </div>
    </div>
  );
};
