import {
  ButtonIcon,
  CardWrapperWithHeader,
  chartProducts,
  FiltersModal,
  FilterValueShape,
  getClaimLimit,
} from '@rabbit/elements/shared-components';
import { useContext, useEffect, useMemo, useState } from 'react';
import Chart from 'react-apexcharts';
import {
  formatDataChart,
  getUserPermissions,
  titleCase,
} from '../utils/helpers';
import {
  DTHoldingProxy,
  Permissions,
  VehicleInfo,
  Warranty,
  WarrantyStatus,
} from '@rabbit/data/types';
import { useGetHoldingList } from '@rabbit/bizproc/react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../context/UserContext';
import { FunnelIcon } from '@heroicons/react/24/outline';
import {
  Config_ClaimDetailViewType,
  SAGE_ROUTE_NAME,
} from '@rabbit/config/enums';
import { useNavigate } from 'react-router-dom';
import { CreateTable, TableFields } from '../utils/CreateTable';
import { AppContext } from '@rabbit/app-context';
import { BL_Warranty } from '@rabbit/bizproc/core';
import i18next from 'i18next';

type WarrantyOverviewShape = Warranty & { holding: DTHoldingProxy };

export default function WarrantyOverviewView() {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const tenantLink = t('tenantLink');
  const userPermissions = getUserPermissions(tenantLink, userContext);
  const navigate = useNavigate();
  const { tenantInfo } = useContext(AppContext);
  const { accessToken, repairerPersonaId, retailerPersonaId } =
    userContext ?? ({} as any);
  // TODO: In the future it would be nice to get this directly from the context - DC
  const activePersonaIds = {
    repairer: repairerPersonaId,
    retailer: retailerPersonaId,
  };
  const [total, setTotal] = useState(0);
  //Set the filters default date range to this month -VP
  const today = new Date();
  const currentMonth = today.getMonth();
  const currentYear = today.getFullYear();
  const beginDate = new Date(currentYear, currentMonth, 1);
  const [filters, setFilters] = useState<FilterValueShape>(
    t('tenantLink') === 'WARRANTYIRELAND'
      ? {
          dateType: 'This month',
          beginDate: beginDate,
          endDate: today,
        }
      : {}
  );
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [chartStatusData, setChartStatusData] = useState<any[]>([]);
  const [chartTemplateData, setChartTemplateData] = useState<any[]>([]);
  const CFG_COBRAND_REGISTRATIONS_SHOW_CLAIMLIMIT_VAT =
    t('CFG_COBRAND_REGISTRATIONS_SHOW_CLAIMLIMIT_VAT') === 'true';
  const { config } = useContext(AppContext);

  const {
    holdingList,
    // getNextPage,
    //totalHoldingCount,
    isLoading,
  } = useGetHoldingList(
    //pageSize,
    t('tenantLink'),
    accessToken,
    activePersonaIds,
    filters
  );

  const onChangeFilters = (value: FilterValueShape) => {
    setFilters(value);
    setIsFilterModalOpen(false);
  };

  const expiredDate = new Date().setHours(-1); // at 23:00 last day
  const upcomingDate = Date.now() + 2 * 30.14 * 24 * 3600 * 1000;
  const warrantyFilters = (holding: DTHoldingProxy) => {
    return (
      holding.warranties.filter((warrantyInfo) => {
        if (!warrantyInfo?.endDate) return holding;
        const expires = warrantyInfo?.endDate < expiredDate;
        const upcoming =
          warrantyInfo?.startDate &&
          warrantyInfo?.startDate > Date.now() &&
          warrantyInfo?.startDate < upcomingDate;
        const status = upcoming
          ? 'upcoming'
          : expires
          ? 'expired'
          : warrantyInfo?.status;
        return filters.warrantyStatus
          ? filters.warrantyStatus?.indexOf(status) > -1
          : true;
      }).length > 0
    );
  };

  const warrantyData = (
    holdings: DTHoldingProxy[]
  ): WarrantyOverviewShape[] => {
    const all = holdings?.filter(warrantyFilters);
    const result: WarrantyOverviewShape[] = [];
    all.forEach((item) => {
      result.push(
        ...item.warranties.map((warranty) => ({
          ...warranty,
          holding: item,
        }))
      );
    });
    return result;
  };

  const WarrantyTable = useMemo(
    () =>
      CreateTable<WarrantyOverviewShape>(
        warrantyData(holdingList.data || []),
        (
          [
            {
              header: 'ID',
              value: (data) => data.warrantyLink?.slice(0, 5).toUpperCase(),
            },
            {
              header: t('general.customerName'),
              value: (data) => data.holding.consumer_name || '-',
            },
            config.CLAIMS.CLAIM_DETAIL_VIEW.TYPE ===
            Config_ClaimDetailViewType.CAR_DETAILS
              ? {
                  header: 'Plate',
                  value: (data) =>
                    BL_Warranty.getVehicleRegistrationNo(
                      data.holding.self_registration?.srvInfo
                        ?.productInfo as VehicleInfo
                    ),
                }
              : undefined,
            {
              header: t('general.registered'),
              type: 'Date',
              value: (data) => {
                return data.registeredTime || data.holding.register_time;
              },
            },
            {
              header: t('general.plan'),
              value: (data) => {
                if (
                  data?.templateTitle &&
                  data.templateTitle === 'Standard Warranty' &&
                  i18next.exists('general.standardWarranty')
                ) {
                  return t('general.standardWarranty');
                } else {
                  return data?.templateTitle || '-';
                }
              },
            },
            {
              header: t('general.start'),
              type: 'Date',
              value: (data) => data?.startDate,
            },
            {
              header: t('general.term'),
              value: (data) =>
                BL_Warranty.getLatestWarranty(data.holding.warranties)
                  ?.templateTitle === 'Standard Endura Door Warranty' ||
                BL_Warranty.getLatestWarranty(data.holding.warranties)
                  ?.templateTitle === 'Platinum Roof Warranty' ||
                BL_Warranty.getLatestWarranty(data.holding.warranties)
                  ?.templateTitle === 'Standard Roof Warranty'
                  ? 'Lifetime'
                  : data.duration
                  ? data.duration.amount + ' ' + data.duration.division
                  : '-',
            },
            {
              header: t('general.expires'),
              type: 'Date',
              value: (data) =>
                BL_Warranty.getLatestWarranty(data.holding.warranties)
                  ?.templateTitle === 'Standard Endura Door Warranty' ||
                BL_Warranty.getLatestWarranty(data.holding.warranties)
                  ?.templateTitle === 'Platinum Roof Warranty' ||
                BL_Warranty.getLatestWarranty(data.holding.warranties)
                  ?.templateTitle === 'Standard Roof Warranty'
                  ? 'Lifetime'
                  : data?.endDate,
            },
            {
              header: t('general.partners'),
              value: (data) => data?.retailerName || '-',
            },
            {
              header: t('general.status'),
              type: 'Status',
              value: (data) => {
                const expiredDate = new Date().setHours(-1); // at 23:00 last day
                const expired = data?.endDate
                  ? data?.endDate < expiredDate
                  : false;
                const upcoming =
                  data?.startDate && data?.startDate > Date.now();
                const translateStatus = data?.status
                  ? `general.${data?.status?.toLowerCase()}`
                  : '-';
                return upcoming
                  ? t('general.upcoming')
                  : expired
                  ? t('general.expired')
                  : t(translateStatus) || '-';
              },
            },
          ] as TableFields<WarrantyOverviewShape>
        ).filter((field) => field !== undefined)
      ),
    [holdingList.data, isLoading, filters, t]
  );

  useEffect(() => {
    const templateData = (holdings: DTHoldingProxy[]) => {
      const totalLabel: any = {};
      holdings.filter(warrantyFilters).forEach((holding) => {
        holding.warranties.forEach((warranty) => {
          totalLabel[warranty?.templateTitle || '-'] =
            (totalLabel[warranty?.templateTitle || '-'] || 0) + 1;
        });
      });
      return Object.keys(totalLabel).map((item, index) => ({
        id: index,
        count: totalLabel[item],
        label: titleCase(item),
      }));
    };

    setChartTemplateData(
      formatDataChart(
        templateData(holdingList?.data || []),
        'series',
        'Warranty plan',
        5
      )
    );

    const statusData = (holdings: DTHoldingProxy[]) => {
      const totalStatus: any = {};
      holdings.filter(warrantyFilters).forEach((holding, index) => {
        holding.warranties.forEach((warranty) => {
          const expiredDate = new Date().setHours(-1); // at 23:00 last day
          const upcomingDate = Date.now() + 2 * 30.14 * 24 * 3600 * 1000;
          const expires = warranty?.endDate && warranty?.endDate < expiredDate;
          const upcoming =
            warranty?.startDate &&
            warranty?.startDate > Date.now() &&
            warranty?.startDate < upcomingDate;
          const status = upcoming
            ? 'upcoming'
            : expires
            ? 'expired'
            : warranty?.status;
          if (status) totalStatus[status] = (totalStatus[status] || 0) + 1;
        });
      });
      return Object.keys(totalStatus).map((item, index) => ({
        id: index,
        count: totalStatus[item],
        label: titleCase(item),
      }));
    };

    setChartStatusData(
      formatDataChart(
        statusData(holdingList?.data || []),
        'series',
        'Warranty status',
        5
      )
    );

    setTotal(
      holdingList?.data
        ?.filter(warrantyFilters)
        .map((i) => i.warranties.length)
        .reduce((a, b) => a + b, 0) || 0
    );
  }, [holdingList?.data, isLoading, filters]);

  //Check if user has permissions to view this page
  if (!userPermissions?.includes(Permissions.Owner)) navigate('/');

  return (
    <>
      <div className="mb-5 grid grid-cols-2 gap-5">
        <CardWrapperWithHeader
          title={t('general.totalWarranties')}
          headerRight={<div className="text-xl font-bold">Total: {total}</div>}
        >
          <Chart
            options={chartProducts.options}
            series={chartTemplateData}
            type="bar"
            height={'250px'}
          />
        </CardWrapperWithHeader>
        <CardWrapperWithHeader
          title={t('general.warrantyStatus')}
          headerRight={<div className="text-xl font-bold">Total: {total}</div>}
        >
          <Chart
            options={chartProducts.options}
            series={chartStatusData}
            type="bar"
            height={'250px'}
          />
        </CardWrapperWithHeader>
      </div>
      <div className="relative z-10 flex w-full items-center justify-between">
        <div className="absolute top-[25px] right-5">
          {!isLoading && (
            <ButtonIcon
              label={t('general.filters')}
              iconLeft={true}
              Icon={FunnelIcon}
              onClick={() => setIsFilterModalOpen(true)}
              kind={'bgLightGreen'}
              count={
                (filters && filters.beginDate) || (filters && filters.endDate)
                  ? 1
                  : 0
              }
            />
          )}
        </div>
        {isFilterModalOpen && (
          <div className="absolute right-0 top-16 z-10">
            <FiltersModal
              handleClose={() => setIsFilterModalOpen(false)}
              data={filters}
              onChange={onChangeFilters}
              option={{ showWarrantyStatus: true }}
            />
          </div>
        )}
      </div>
      <div className="relative z-0 w-full items-center justify-between py-4">
        <WarrantyTable
          initialState={{
            showGlobalFilter: true,
            sorting: [
              {
                id: 'registered',
                desc: true,
              },
            ],
          }}
          muiSearchTextFieldProps={{
            placeholder: t('general.searchByWarrantyIDOrCustomerName'),
          }}
          state={{
            isLoading,
          }}
          onClickRow={(data) => {
            navigate(
              SAGE_ROUTE_NAME.WARRANTY_OVERVIEW + '/' + data?.holding.docid,
              {
                state: {
                  title: (
                    data?.holding?.docid?.slice(0, 5) || ''
                  ).toUpperCase(),
                  link: data?.holding.docid,
                },
              }
            );
          }}
        />
      </div>
    </>
  );
}
