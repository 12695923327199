import { Formik, Form } from 'formik';
import {
  Button,
  LoadingSpinner,
  getCountryByLabel,
  Input,
} from '@rabbit/elements/shared-components';
import { useContext, useEffect, useRef, useState } from 'react';
import { SelectAddressShape } from '@rabbit/elements/shared-types';
import { NewClaimModalSteps } from '../../ModalNewClaim/ModalNewClaim';
import { t } from 'i18next';
import {
  CreateNewConsumerForm_FieldNames,
  CreateNewConsumerForm_FormValues,
  ModalNewRegistration_ConfigShape,
} from '../../ModalNewRegistration/tenant-config/types.ts';
import { AppContext } from '@rabbit/app-context';
import { CORPORATE } from '@rabbit/sage/utils/consts.ts';

async function getFormConfig(tenantLink: string, type: string | null) {
  const fileName = tenantLink.toLowerCase();
  try {
    if (type && type === CORPORATE) {
      return await import(
        `../../ModalNewRegistration/tenant-config/business-generic-flow.tsx`
      );
    } else {
      return await import(
        `../../ModalNewRegistration/tenant-config/${fileName}.tsx`
      );
    }
  } catch (e) {
    console.warn(
      `File ${`../../ModalNewRegistration/tenant-config/${fileName}.tsx`} not found`
    );
    return await import(
      '../../ModalNewRegistration/tenant-config/common-generic-flow.tsx'
    );
  }
}

export interface CreateNewConsumerFormProps {
  handleClose: () => void;
  onChange: (step: NewClaimModalSteps, data: any) => void;
  data: any;
}

export function CreateNewConsumerForm(props: CreateNewConsumerFormProps) {
  const { onChange, handleClose } = props;
  const tenantLink = t('tenantLink');
  const { tenantInfo } = useContext(AppContext);
  const [formConfig, setFormConfig] =
    useState<ModalNewRegistration_ConfigShape | null>(null);

  const [type, setType] = useState<string | null>(props.data?.type ?? '');

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedAddress, setSelectedAddress] =
    useState<SelectAddressShape | null>({} as SelectAddressShape);

  const formikRef = useRef(null) as any; //TODO

  const onSubmit = (values: any) => {
    setIsSubmitting(true);
    const data = {
      previewData: {
        newUser: values,
      },
      ...values,
      consumer_telephone:
        values.consumer_telephone_e164 ?? values.consumer_telephone,
    };
    onChange('add-product', data);
  };

  useEffect(() => {
    getFormConfig(tenantLink, type).then((module) => {
      setFormConfig(module.ModalNewRegistration_Config);
    });
  }, []);

  useEffect(() => {
    if (!selectedAddress) {
      formikRef.current.resetForm();
      return;
    }
    if (formikRef && selectedAddress?.postal_code) {
      formikRef.current.setFieldValue(
        'consumer_address.line1',
        selectedAddress.line_1
      );
      formikRef.current.setFieldValue(
        'consumer_address.address_line_1',
        selectedAddress.line_1
      );
      formikRef.current.setFieldValue(
        'consumer_address.line2',
        selectedAddress.line_2
      );
      formikRef.current.setFieldValue(
        'consumer_address.address_line_2',
        selectedAddress.line_2
      );
      formikRef.current.setFieldValue(
        'consumer_address.town',
        selectedAddress.locality
      );
      formikRef.current.setFieldValue(
        'consumer_address.state',
        selectedAddress.province_name
      );
      formikRef.current.setFieldValue(
        'consumer_address.postcode',
        selectedAddress.postal_code
      );
      formikRef.current.setFieldValue(
        'consumer_address.country',
        getCountryByLabel(selectedAddress.country_name ?? '')?.value ?? ''
      );
    }
  }, [selectedAddress]);

  if (!formConfig) {
    return <LoadingSpinner size="xs" />;
  }

  const initialValues: Partial<CreateNewConsumerForm_FormValues> =
    formConfig.CreateNewConsumerForm.generateInitialValues({
      consumer_address: {
        line1: '',
        line2: '',
        town: '',
        state: '',
        postcode: '',
        country: tenantInfo?.country ?? '',
      },
    });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={formConfig.CreateNewConsumerForm.validationSchema}
      onSubmit={onSubmit}
      validateOnChange={true}
      validateOnBlur={false}
      innerRef={formikRef}
    >
      {(props) => (
        <Form className="flex-col gap-3 p-5">
          {type === CORPORATE && (
            <div className="w-full py-2">
              <div className="flex w-full gap-4">
                {formConfig.CreateNewConsumerForm.generateInput(
                  CreateNewConsumerForm_FieldNames.business_name
                )}
                {formConfig.CreateNewConsumerForm.generateInput(
                  CreateNewConsumerForm_FieldNames.tax_id
                )}
              </div>
            </div>
          )}
          {type === CORPORATE && (
            <div className="font-nunito text-md mt-5 mb-3">
              <span>{t('general.primaryContact')}</span>
            </div>
          )}
          <div className="flex gap-4">
            {formConfig.CreateNewConsumerForm.generateInput(
              CreateNewConsumerForm_FieldNames.consumer_first_name
            )}
            {formConfig.CreateNewConsumerForm.generateInput(
              CreateNewConsumerForm_FieldNames.consumer_last_name
            )}
          </div>
          <div className="mt-4 flex items-start gap-4">
            {formConfig.CreateNewConsumerForm.generateInput(
              CreateNewConsumerForm_FieldNames.consumer_email
            )}
            {formConfig.CreateNewConsumerForm.generateInput(
              CreateNewConsumerForm_FieldNames.consumer_is_commercial,
              {
                errors: props.errors,
              }
            )}
          </div>
          <div className="mt-4 flex items-start gap-4">
            {formConfig.CreateNewConsumerForm.generateInput(
              CreateNewConsumerForm_FieldNames.consumer_telephone
            )}
            {formConfig.CreateNewConsumerForm.generateInput(
              CreateNewConsumerForm_FieldNames.consumer_preferred_contact,
              { errors: props.errors }
            )}
          </div>
          {type === CORPORATE && (
            <>
              <div className="font-nunito text-md mt-5 mb-3">
                <span>{t('general.secondaryContact')}</span>
              </div>
              <div className="w-full py-2">
                <div className="flex w-full gap-4">
                  {formConfig.CreateNewConsumerForm.generateInput(
                    CreateNewConsumerForm_FieldNames.secondary_first_name
                  )}
                  {formConfig.CreateNewConsumerForm.generateInput(
                    CreateNewConsumerForm_FieldNames.secondary_last_name
                  )}
                </div>
              </div>
              <div className="w-full py-2">
                <div className="flex w-full gap-4">
                  {formConfig.CreateNewConsumerForm.generateInput(
                    CreateNewConsumerForm_FieldNames.secondary_email
                  )}
                  {formConfig.CreateNewConsumerForm.generateInput(
                    CreateNewConsumerForm_FieldNames.secondary_phone_number
                  )}
                </div>
              </div>
            </>
          )}
          <div className="mt-4">
            <div className="mb-2">
              <label className="font-nunito text-base font-medium">
                {type === CORPORATE
                  ? t('general.businessAddress')
                  : t('general.customerAddress')}
              </label>
            </div>
            <div className="mb-2">
              {formConfig.CreateNewConsumerForm.generateInput(
                CreateNewConsumerForm_FieldNames.consumer_address_country
              )}
            </div>
            {formConfig.CreateNewConsumerForm.generateInput(
              CreateNewConsumerForm_FieldNames.consumer_address,
              {
                onChange: (address: any) => setSelectedAddress(address),
                errors: props.errors['consumer_address'],
                country: props.values?.consumer_address?.country,
              }
            )}
          </div>
          <div className="mt-4">
            {formConfig.CreateNewConsumerForm.generateInput(
              CreateNewConsumerForm_FieldNames.consumer_address_line1
            )}
          </div>
          <div className="mt-4">
            {formConfig.CreateNewConsumerForm.generateInput(
              CreateNewConsumerForm_FieldNames.consumer_address_line2
            )}
          </div>
          <div className="mt-4 flex gap-3">
            {formConfig.CreateNewConsumerForm.generateInput(
              CreateNewConsumerForm_FieldNames.consumer_address_town
            )}
            {formConfig.CreateNewConsumerForm.generateInput(
              CreateNewConsumerForm_FieldNames.consumer_address_state
            )}
          </div>
          <div className="mt-4 flex gap-3">
            {formConfig.CreateNewConsumerForm.generateInput(
              CreateNewConsumerForm_FieldNames.consumer_address_postcode
            )}
          </div>
          <div className="mt-5 flex w-full gap-4">
            <Button kind="primary" type="submit" loading={isSubmitting}>
              {t('Continue')}
            </Button>
            <Button kind="outline_red" type="submit" onClick={handleClose}>
              {t('Cancel')}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default CreateNewConsumerForm;
