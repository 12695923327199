import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Button, Input } from '@rabbit/elements/shared-components';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  ConsumerIssueTypeHookOrigins,
  FileStorageContext,
  getCompletedUploadsOfCategory,
  LIST_COUNTRIES,
  useManageConsumerIssueTypes,
  useSageAPI,
} from '@rabbit/bizproc/react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { NewClaimModalSteps } from '../../../ModalNewClaim/ModalNewClaim';
import {
  DTConsumer_Public,
  DTHoldingProxy,
  PersonaIdTypeSplitter,
  PersonaTypeSingleLetter,
  UserUploadedDocument,
} from '@rabbit/data/types';
import {
  Caseflow_CaseCreation_Sage_Fatbikes_FormDataShape,
  CaseflowCaseTypes,
  RegisterCaseOrigins,
} from '@rabbit/bizproc/core';
import { OurDateTime } from '@rabbit/utils/ts';
import { SageFileUploader } from '../../../upload-wrapper/SageFileUploader';
import {
  CompletedUploadShape,
  DocTypeShapeTypes,
  UploadedFileCategories,
} from '@rabbit/elements/shared-types';
import { useGetMySagePersonas } from '@rabbit/data/portal';
import { WARRANTY_STATUS_OPTIONS } from '@rabbit/sage/utils/consts';
import { AppContext } from '@rabbit/app-context';

// TODO: EXTRACT GENERIC BITS OF THIS AND NCCHF_Base INTO A SHARED COMPONENT
// TODO: Add a check for existing uploaded serial proofs (from olive)
// TODO: Internal comment / external repairer comment variation
export interface NCCHF_FatbikesProps {
  onHoldingCreated: (result: any) => void;
  handleClose: () => void;
  setLoading: Dispatch<SetStateAction<boolean>>;
  onChange: (step: NewClaimModalSteps, data?: any) => void;
  data: { holding: DTHoldingProxy; consumer: DTConsumer_Public };
}

interface HoldingDataCase {
  consumer_name: string;
  purchase_date: Date;
  consumer_issue_type_ref: string;
  purchase_location: {
    country: string;
    docid: string;
  } | null;
  store_not_listed: boolean;
  custom_store_name: string;
  product_country: string;
  consumer_issue_description: string;
  holding_vendable_id: string;
  serial_number: string;
  serial_number_proof: UserUploadedDocument[];
  internal_comment: string;
  warranty_status: string;
  consumer_claim_evidence: UserUploadedDocument[];
  mileage: {
    addon: string;
    unit: string;
    value: number;
  };
  business_name: string;
}

export function NCCHF_Fatbikes(props: NCCHF_FatbikesProps) {
  const { handleClose, onChange, data, onHoldingCreated, setLoading } = props;
  const personas = useGetMySagePersonas();
  const formikRef = useRef(null) as any;
  const { t } = useTranslation();
  const { config } = useContext(AppContext);
  const activePremiumTenantLink = t('tenantLink');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDatePurchaseDisabled, setIsDatePurchaseDisabled] = useState(false);

  const { initiateCaseFlowCase } = useSageAPI();

  const { consumerIssueTypeList } = useManageConsumerIssueTypes(
    ConsumerIssueTypeHookOrigins.SAGE
  );
  const consumerIssueTypeOptions = !consumerIssueTypeList?.data
    ? []
    : consumerIssueTypeList.data.map((issue) => ({
        id: issue.docid,
        value: issue.docid,
        label: issue.label,
      })) ?? [];

  //console.log(data, 'data'); // @TEST_REMOVE_LATER
  const initialValues: HoldingDataCase = {
    consumer_name: data.consumer?.fullname,
    holding_vendable_id: '',
    purchase_date: new Date(),
    consumer_issue_type_ref: '',
    purchase_location: null,
    store_not_listed: false,
    custom_store_name: '',
    product_country: '',
    consumer_issue_description: '',
    internal_comment: '',
    serial_number: data.holding?.serial ?? '',
    serial_number_proof: data.holding?.serial_proof ?? [],
    consumer_claim_evidence: [],
    warranty_status: '',
    mileage: {
      addon: 'km',
      unit: 'km',
      value: 0,
    },
    business_name: data.holding?.business_name ?? '',
  };

  const {
    uploadQueueState,
    moveCompletedUploadsToAttached,
    isUpdating,
    updateHoldingWithFiles,
  } = useContext(FileStorageContext) || {};

  const validationSchema = Yup.object().shape(
    data.holding
      ? {
          consumer_name: Yup.string().trim(),
          consumer_issue_type_ref: Yup.string()
            .trim()
            .required(t('general.issueTypeMustBeSelected')),
          consumer_issue_description: Yup.string().trim(),
          internal_comment: Yup.string().trim(),
          ...(config.CLAIMS.CLAIMS_FLOW.NEW_CLAIM.SERIAL_NUMBER && {
            serial_number: Yup.string()
              .trim()
              .required(t('general.pleaseEnterSerialNumber')),
          }),
          // serial_number_proof: Yup.array()
          //   .min(1)
          //   .required('Please upload a photo of the serial number'),
          consumer_claim_evidence: Yup.array(),
          warranty_status: Yup.string(),
          ...(config.CLAIMS.CLAIMS_FLOW.NEW_CLAIM.MILEAGE && {
            mileage: Yup.object({
              addon: Yup.string().required('Please select the mileage unit'),
              value: Yup.string().trim().required('Please enter mileage'),
            }),
          }),
        }
      : {
          consumer_name: Yup.string().trim(),
          holding_vendable_id: Yup.string()
            .trim()
            .required(t('general.productNameCantBeEmpty')),
          purchase_date: Yup.string().nullable().trim(),
          consumer_issue_type_ref: Yup.string()
            .trim()
            .required(t('general.issueTypeMustBeSelected')),
          purchase_location: Yup.mixed(),
          store_not_listed: Yup.boolean(),
          consumer_issue_description: Yup.string().trim(),
          internal_comment: Yup.string().trim(),
          ...(config.CLAIMS.CLAIMS_FLOW.NEW_CLAIM.SERIAL_NUMBER && {
            serial_number: Yup.string()
              .trim()
              .required(t('general.pleaseEnterSerialNumber')),
          }),

          // serial_number_proof: Yup.array()
          //   .min(1)
          //   .required('Please upload a photo of the serial number'),
          consumer_claim_evidence: Yup.array(),
          warranty_status: Yup.string(),
          ...(config.CLAIMS.CLAIMS_FLOW.NEW_CLAIM.MILEAGE && {
            mileage: Yup.object({
              addon: Yup.string().required('Please select the mileage unit'),
              value: Yup.string().trim().required('Please enter mileage'),
            }),
          }),
        }
  );

  const { completed } = uploadQueueState ?? {};

  const serialNumberProofUploads: CompletedUploadShape[] =
    getCompletedUploadsOfCategory(
      completed ?? [],
      UploadedFileCategories.SerialNumberProof
    );

  const caseEvidenceuploads: CompletedUploadShape[] =
    getCompletedUploadsOfCategory(
      completed ?? [],
      UploadedFileCategories.ConsumerCaseEvidence
    );

  // TODO: check this on submit func to save the case
  const onSubmit = async (values: HoldingDataCase) => {
    setIsSubmitting(true);
    setLoading(true);

    const serialNumberProofFiles = serialNumberProofUploads?.flatMap((file) =>
      file.category === UploadedFileCategories.SerialNumberProof &&
      file?.uploadedFile
        ? [file.uploadedFile]
        : []
    );

    const caseEvidenceFiles = caseEvidenceuploads?.flatMap((file) =>
      file.category === UploadedFileCategories.ConsumerCaseEvidence &&
      file?.uploadedFile
        ? [file.uploadedFile]
        : []
    );

    const repairerLink = personas.repairerPersona?.personaId;
    let warrantorLink = personas.warrantorPersona?.personaId;
    let warrantyDealerLink = personas.warrantyDealerPersona?.personaId;
    if (personas.consumerPersona?.personaId) {
      warrantorLink = `${
        PersonaTypeSingleLetter.Warrantor
      }${PersonaIdTypeSplitter}${t('tenantLink')}`;
    }

    // If values include a retailer from the database, then purchase_location_other should be cleared - and vice versa
    const newPurchaseLocation =
      values.custom_store_name && values.store_not_listed
        ? null
        : values.purchase_location?.docid ?? '';

    const newPurchaseCountry =
      values.custom_store_name && values.store_not_listed
        ? values.product_country
        : values.purchase_location?.country ?? '';

    const newPurchaseLocationOther = values.store_not_listed
      ? values.custom_store_name ?? ''
      : null;

    const finalData: Caseflow_CaseCreation_Sage_Fatbikes_FormDataShape =
      data.holding
        ? {
            consumer_first_name: data.consumer.splitname?.first,
            consumer_last_name: data.consumer.splitname?.last,
            consumer_address: data.consumer.address,
            consumer_email: data.consumer.email,
            consumer_telephone: data.consumer.phone,
            consumer_preferred_contact: data.consumer.preferred_contact,
            holding_vendable_id: data.holding.vendable,
            consumer_holding_purchase_location: newPurchaseLocation,
            consumer_holding_purchase_country: newPurchaseCountry,
            consumer_holding_purchase_location_other: newPurchaseLocationOther,
            purchase_date: data.holding.purchase_time,
            consumer_issue_type:
              consumerIssueTypeOptions.find(
                (option) => option.id === values.consumer_issue_type_ref
              )?.label ?? '',
            consumer_issue_type_ref: values.consumer_issue_type_ref,
            serial_number: values.serial_number,
            serial_number_proof: serialNumberProofFiles ?? [],
            consumer_claim_evidence: caseEvidenceFiles ?? [],
            ...(repairerLink && !warrantorLink
              ? { external_repairer_comment: values.internal_comment ?? '' }
              : {}),
            ...(warrantorLink
              ? { internal_comment: values.internal_comment ?? '' }
              : {}),
            consumer_issue_description: values.consumer_issue_description,
            warranty_status: values.warranty_status,
            mileage: values.mileage
              ? {
                  unit: values.mileage?.unit ?? 'km',
                  value: values.mileage?.value ?? 'km',
                }
              : { unit: 'km', value: 0 },
          }
        : ({
            ...data,
            ...values,
            consumer_holding_purchase_location: newPurchaseLocation,
            consumer_holding_purchase_country: newPurchaseCountry,
            consumer_holding_purchase_location_other: newPurchaseLocationOther,
            purchase_date: values.purchase_date
              ? OurDateTime.dateToTimestamp(values.purchase_date)
              : null,
            consumer_issue_type:
              consumerIssueTypeOptions.find(
                (option) => option.id === values.consumer_issue_type_ref
              )?.label ?? '',
            consumer_issue_type_ref: values.consumer_issue_type_ref,
            serial_number: values.serial_number,
            serial_number_proof: serialNumberProofFiles ?? [],
            consumer_claim_evidence: caseEvidenceFiles ?? [],
            consumer_issue_description: values.consumer_issue_description,
            warranty_status: values.warranty_status,
            mileage: values.mileage
              ? {
                  unit: values.mileage?.unit ?? 'km',
                  value: values.mileage?.value ?? 'km',
                }
              : { unit: 'km', value: 0 },
          } as any);

    try {
      const res = await initiateCaseFlowCase({
        origin: RegisterCaseOrigins.SAGE_FATBIKES,
        caseType: CaseflowCaseTypes.FATBIKES,
        tenantLink: activePremiumTenantLink,
        consumerLink: data.consumer.docid,
        holdingLink: data.holding?.holdingLink,
        // use repairerLink if no warrantorLink is present
        ...(repairerLink &&
          !warrantorLink && {
            repairerLink,
          }),
        ...(warrantorLink && { warrantorLink }),
        ...(warrantyDealerLink && { warrantyDealerLink }),
        formData: finalData,
      });

      // console.log('res', res);
      if (res.holdingLink && res.consumerLink) {
        if (values.warranty_status) {
          onHoldingCreated({
            case_id: res.caseId,
            consumer_persona_id: res.consumerLink,
            holding_id: res.holdingLink,
          });
        }
        onHoldingCreated({
          case_id: res.caseId,
          consumer_persona_id: res.consumerLink,
          holding_id: res.holdingLink,
        });

        if (
          moveCompletedUploadsToAttached &&
          updateHoldingWithFiles &&
          serialNumberProofFiles &&
          serialNumberProofFiles.length > 0
        ) {
          await updateHoldingWithFiles(
            res.holdingLink,
            serialNumberProofFiles,
            UploadedFileCategories.SerialNumberProof
          );
          moveCompletedUploadsToAttached(serialNumberProofUploads);
        }

        if (
          moveCompletedUploadsToAttached &&
          caseEvidenceFiles &&
          caseEvidenceFiles.length > 0
        ) {
          moveCompletedUploadsToAttached(caseEvidenceuploads);
        }
      }

      handleClose();
    } catch (err: any) {
      console.log(err);
      toast.error(
        err?.message ?? t('message.somethingWentWrongPleaseTryAgain')
      );
      setIsSubmitting(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (formikRef && formikRef.current && isDatePurchaseDisabled) {
      formikRef.current.setFieldValue('purchase_date', null);
    }
  }, [isDatePurchaseDisabled]);

  if (!data.holding) {
    return (
      <div className="p-5">
        <p className="pb-3">{t('general.noValidProductsFound')}</p>
        <div className="flex">
          <Button kind="primary" onClick={() => onChange('select-user')}>
            {t('general.searchAgain')}
          </Button>
        </div>
      </div>
    );
  }
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      innerRef={formikRef}
    >
      {({ errors, values, isValid, dirty, setFieldValue, setFieldError }) => (
        <Form className="flex-col gap-3 px-5">
          {initialValues.business_name && (
            <div className="mt-4 w-full">
              <Input
                type="text"
                name="business_name"
                label={t('general.businessName')}
                settings={{
                  id: 'business_name',
                  placeholder: t('general.businessName'),
                  disabled: true,
                }}
              />
            </div>
          )}
          <div className="mt-4 w-full">
            <Input
              type="text"
              name="consumer_name"
              label={t('general.customerName')}
              settings={{
                id: 'consumer_name',
                placeholder: t('general.customerName'),
                disabled: true,
              }}
            />
          </div>

          {!data.holding && (
            <div className="mt-4 grid grid-cols-2 gap-4">
              <>
                <Input
                  type="autocomplete-vendable"
                  name="holding_vendable_id"
                  label={`${t('products')}*`}
                  settings={{
                    isMulti: false,
                    id: 'holding_vendable_id',
                    placeholder: t('general.productName'),
                    options: [],
                    errors: errors,
                    hint: '*required',
                    tenantLink: t('tenantLink'),
                  }}
                />
                <div className="w-full flex-col">
                  <Input
                    type="datepicker"
                    name="purchase_date"
                    label={t('general.dateOfPurchase')}
                    settings={{
                      id: 'purchase_date',
                      placeholder: t('general.dateOfPurchase'),
                      maxDate: new Date(),
                      disabled: isDatePurchaseDisabled,
                    }}
                  />
                  <div className="flex items-center pt-2">
                    <Input
                      type="checkbox"
                      name="noDateOfPurchase"
                      settings={{
                        checkboxLabel: t('general.dateNotKnown'),
                        checkboxLabelStyles: 'text-base text-gray-500',
                        onChange: () =>
                          setIsDatePurchaseDisabled(!isDatePurchaseDisabled),
                      }}
                    />
                  </div>
                </div>
              </>
            </div>
          )}

          <div
            className={`mt-4 grid gap-4 ${
              data.holding ? 'grid-cols-1' : 'grid-cols-2'
            } `}
          >
            <Input
              type="select"
              name="consumer_issue_type_ref"
              label={`${t('general.customerIssueType')}*`}
              settings={{
                placeholder: t('general.productDefect'),
                options: consumerIssueTypeOptions,
                hint: '*required',
              }}
            />
            {!data.holding && (
              <div className="flex grow flex-col">
                <Input
                  type="autocomplete-location-retailer"
                  name="purchase_location"
                  label={t('general.storeOfPurchase')}
                  settings={{
                    isMulti: false,
                    placeholder: t('general.pleaseSelectAnOption'),
                    options: [],
                    disabled: values.store_not_listed,
                  }}
                />
                <Input
                  type="checkbox"
                  name="store_not_listed"
                  settings={{
                    checkboxLabel: t('general.storeIsNotListed'),
                    checkboxLabelStyles: 'text-base text-gray-500',
                  }}
                />
                {values.store_not_listed && (
                  <div className="mt-2 flex flex-col gap-2">
                    <Input
                      type="text"
                      name="custom_store_name"
                      settings={{
                        id: 'custom_store_name',
                        placeholder: t('general.enterStoreName'),
                      }}
                    />
                    <Input
                      type="select"
                      label=""
                      name="purchase_country"
                      settings={{
                        options: LIST_COUNTRIES,
                        id: 'purchase_country',
                        placeholder: t(
                          'general.countryWhereProductWasPurchased'
                        ),
                      }}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="mt-4 flex items-center gap-4">
            <Input
              type="text"
              name="consumer_issue_description"
              label={t('general.customerIssueDescription')}
              settings={{
                id: 'consumer_issue_description',
                placeholder: t('general.enterTextHere'),
                allowSpecialCharacter: true,
              }}
            />
          </div>
          {config.CLAIMS.CLAIMS_FLOW.NEW_CLAIM.MILEAGE && (
            <div className="mt-4 flex items-center gap-4">
              <Input
                type="selectAddonText"
                name="mileage"
                label={t('general.mileage') + '*'}
                settings={{
                  id: 'mileage',
                  addonOptions: [
                    { id: '0', label: 'km', value: 'km' },
                    { id: '1', label: 'mi', value: 'mi' },
                  ],
                  placeholder: 'Enter mileage',
                  type: 'number',
                  removeStartZero: true,
                }}
              />
            </div>
          )}
          {config.CLAIMS.CLAIMS_FLOW.NEW_CLAIM.SERIAL_NUMBER && (
            <div className="mt-4 flex items-center gap-4">
              <Input
                type="text"
                name="serial_number"
                label={`${t('general.productSerialNumber')}*`}
                settings={{
                  id: 'serial_number',
                  placeholder: t('general.enterSerialNumberHere'),
                  allowSpecialCharacter: true,
                  hint: '*required',
                  value: data.holding?.serial ?? '',
                  disabled: !!data.holding.serial,
                }}
              />
            </div>
          )}
          {config.WARRANTIES.MANAGEMENT.CAN_SET_WARRANTY_STATUS &&
            !personas.consumerPersona?.personaId && (
              <div className="mt-4 flex items-center gap-4">
                <Input
                  type="select"
                  name="warranty_status"
                  label={`${t('general.warrantyStatus')}`}
                  settings={{
                    id: 'warranty_status',
                    placeholder: t('message.pleaseSelectAnOption'),
                    options: WARRANTY_STATUS_OPTIONS,
                  }}
                />
              </div>
            )}
          {config.CLAIMS.CLAIMS_FLOW.SERIAL_NUMBER_PROOF && (
            <SageFileUploader
              label={t('general.photoOfSerialNumber')}
              identifiers={{
                category: UploadedFileCategories.SerialNumberProof,
                docType: {
                  docid: data.holding.holdingLink,
                  type: DocTypeShapeTypes.Holding,
                },
                personaId: data.consumer.docid ?? '',
              }}
              maxFiles={1}
              onDeleteFile={() => setFieldValue('serial_number_proof', [])}
              currentFiles={values.serial_number_proof ?? []}
              accepts={['image/*', '.pdf']}
            />
          )}

          <SageFileUploader
            label={t('general.supportingEvidence')}
            identifiers={{
              category: UploadedFileCategories.ConsumerCaseEvidence,
              docType: {
                docid: data.holding.holdingLink,
                type: DocTypeShapeTypes.Case,
              },
              personaId: data.consumer.docid ?? '',
            }}
            accepts={['image/*', 'video/*', '.pdf']}
          />
          {!personas.consumerPersona?.personaId && (
            <div className="mt-4 flex items-center gap-4">
              <Input
                type="text"
                name="internal_comment"
                label={t('general.internalComment')}
                settings={{
                  id: 'internal_comment',
                  placeholder: t('general.enterTextHere'),
                  allowSpecialCharacter: true,
                }}
              />
            </div>
          )}

          <div className="mt-5 flex w-full gap-4">
            <Button
              kind="primary"
              type="submit"
              disabled={
                Object.keys(errors).length > 0 || isSubmitting
                // (serialNumberProofUploads.length === 0 &&
                //   values.serial_number_proof.length === 0)
              }
              loading={isSubmitting}
            >
              {t('general.createClaim')}
            </Button>
            <Button kind="outline_red" type="submit" onClick={handleClose}>
              {t('general.cancel')}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default NCCHF_Fatbikes;
