import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from '@rabbit/elements/shared-components';
import { XMarkIcon } from '@heroicons/react/24/solid';

interface ClaimReadinessAssessmentModalProps {
  onClose: () => void;
  sections: ValidationSection[];
}

export interface ValidationItem {
  message: string;
  isValid: boolean;
}

export interface ValidationSection {
  name: string;
  icon: React.ReactNode;
  items: ValidationItem[];
  sectionId: string;
  actionButtonLabel: string;
  actionButtonClick?: any;
}

export default function ClaimReadinessAssessmentModal({
  onClose,
  sections,
}: ClaimReadinessAssessmentModalProps) {
  const { t } = useTranslation();

  return (
    <Modal
      settings={{
        title: t('general.claimReadinessAssessment'),
        headerBackground: true,
        handleClose: onClose,
      }}
      kind="generic"
      isLoading={false}
      className="max-h-[768px] w-full max-w-[500px] overflow-y-auto"
    >
      <div className="p-4">
        {sections.map((section) => {
          const hasError = section.items.some((item) => !item.isValid);

          return (
            <div
              key={section.name}
              className={`mb-4 rounded-lg border border-gray-300 p-4`}
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2 text-gray-700">
                  {/* {section.icon} */}
                  <h3 className="text-lg font-semibold text-gray-800">
                    {section.name}
                  </h3>
                </div>
              </div>

              <ul className="mt-2 list-disc">
                {section.items.map((item, index) => (
                  <li
                    key={index}
                    className={`flex items-center rounded text-gray-600`}
                  >
                    <span>{item.message}</span>
                    {!item.isValid && (
                      <XMarkIcon className="h-4 w-4 ml-2 text-red-500" />
                    )}
                  </li>
                ))}
              </ul>
              <div className="flex items-center gap-2 text-gray-700">
                {/* {section.icon} */}
                <h3 className="text-primary-900 mt-2 font-medium">
                  Status :{' '}
                  <span
                    className={`${
                      hasError ? 'text-red-500' : 'text-green-500'
                    }`}
                  >
                    {hasError
                      ? section.name === 'Geo Location Details'
                        ? 'Potential  exclusion -  FM Event'
                        : 'Incomplete'
                      : 'Complete'}
                  </span>
                </h3>
              </div>

              {hasError && (
                <Button
                  className="mt-5 w-full"
                  size="sm"
                  kind="primary"
                  children={section.actionButtonLabel}
                  onClick={() =>
                    section.actionButtonClick && section.actionButtonClick()
                  }
                />
              )}
            </div>
          );
        })}
      </div>
    </Modal>
  );
}
