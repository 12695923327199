import {
  ButtonIcon,
  CardWrapperWithHeader,
  Modal,
  Input,
  Button,
} from '@rabbit/elements/shared-components';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { AppContext } from '@rabbit/app-context';
import { useContext, useMemo, useState } from 'react';
import { CreateTable } from '@rabbit/sage/utils/CreateTable';
import { CaseflowContext } from '@rabbit/sage/context/CaseflowContext';
import { PencilSquareIcon, PlusIcon } from '@heroicons/react/24/outline';

const ClaimSettlementSection = () => {
  const { t } = useTranslation();
  const { tenantInfo } = useContext(AppContext);
  const [modalData, setModalData] = useState<any>(null);
  const [settlementModal, setSettlementModal] = useState(false);
  const [allSettlement, setAllSettlement] = useState<any>([]);

  const caseFlowContext = useContext(CaseflowContext);
  const { caseId } = caseFlowContext || {};

  const handleSettlementModal = (data = null) => {
    setModalData(data);
    setSettlementModal(true);
  };

  const validationSchema = Yup.object().shape({
    settlementType: Yup.string().required(t('Required')),
    approvals: Yup.string().required(t('Required')),
    price: Yup.object({
      amount: Yup.number().required(t('Required')),
      currency: Yup.string().required(t('Required')),
    }),
    approvalDate: Yup.date().required(t('Required')).default(new Date()),
    calimId: Yup.string().required(t('Required')),
    expiry: Yup.date().when('settlementType', {
      is: 'voucher', // If settlementType is 'voucher'
      then: Yup.date().required(t('Required')), // Expiry is required
      otherwise: Yup.date().optional(), // Otherwise, expiry is optional
    }),
    reason: Yup.string().optional(),
  });

  const initialState = {
    settlementType: '',
    approvals: '',
    claimInfo: '',
    approvalDate: new Date(),
    calimId: caseId,
    expiry: '',
    price: {
      amount: 0,
      currency: tenantInfo?.currency,
    },
    reason: '',
  };

  // Table for Settlements
  const SettlementTable = useMemo(
    () =>
      CreateTable(
        allSettlement,
        [
          {
            header: t('Type'),
            value: (settlement: any) =>
              settlement.settlementType
                .split(' ')
                .map((w: any) => w.charAt(0).toUpperCase() + w.slice(1))
                .join(' '),
            size: 100,
          },
          {
            header: t('Secondary Approval'),
            value: (settlement: any) => settlement.approvals==='no'?'Approved':'Pending',
            size: 100,
          },
          {
            header: t('Approval Date'),
            type: 'Date',
            value: (settlement: any) => settlement.approvalDate,
            size: 100,
          },
          {
            header: t('Claim Id'),
            value: (settlement: any) =>
              settlement.calimId.toUpperCase().slice(0, 5),
            size: 100,
          },
          {
            header: t('Amount'),
            value: (settlement: any) =>
              `${settlement.price.amount} ${settlement.price.currency}`,
            size: 100,
          },
          {
            header: t('Expiry'),
            type: 'Date',
            value: (settlement: any) => settlement.expiry || '-',
            size: 100,
          },
          {
            header: 'Actions',
            accessorKey: 'actions',
            size: 15,
            value: (invoice) => invoice,
            Cell: ({ cell }: any) => {
              const invoice = allSettlement[cell.row.index];
              return (
                <div className="text-primary-900 flex cursor-pointer justify-center gap-1 text-right">
                  <PencilSquareIcon
                    className="h-[20px] w-[20px]"
                    onClick={() => {
                      setModalData(invoice);
                      setSettlementModal(true);
                    }}
                  />
                  {/* <TrashIcon
                  className="h-[20px] w-[20px]"
                  onClick={() => {
                    setModalData(invoice);
                    // setSettlementModal(true);
                  }}
                /> */}
                </div>
              );
            },
          },
        ],
        'w-full'
      ),
    [allSettlement, t]
  );

  return (
    <CardWrapperWithHeader
      title={t('Settlement')}
      noPadding
      collapsedByDefault={false}
      smaller={true}
      headerRight={
        <ButtonIcon
          type="primary"
          label={t('Add Settlement')}
          Icon={PlusIcon}
          iconLeft
          onClick={handleSettlementModal}
        />
      }
      extraStyles="max-w-[798px]"
    >
      {allSettlement.length === 0 ? (
        <p className="p-5"> {t('No settlement added yet!')} </p>
      ) : (
        <div className="relative h-auto w-full">
          <SettlementTable
            enablePagination={allSettlement.length > 10}
            initialState={{ showGlobalFilter: true }}
            muiSearchTextFieldProps={{
              sx: { display: 'none' },
            }}
            muiTopToolbarProps={{
              sx: { display: 'none' },
            }}
            muiTableHeadCellProps={{
              className: 'relative bg-gray-200 uppercase font-light',
            }}
            muiTableBodyCellProps={{
              className: 'px-4 py-0',
            }}
          />
        </div>
      )}

      {settlementModal && (
        <Modal
          className="w-[550px]"
          kind="generic"
          settings={{
            title: modalData ? t('Edit Settlement') : t('Add Settlement'),
            primaryButtonText: modalData ? t('Update') : t('Save'),
            outlineButtonText: t('Cancel'),
            handlePrimaryClick: () => {},
            handleOutlineClick: () => {
              setModalData(null);
              setSettlementModal(false);
            },
            handleClose: () => {
              setModalData(null);
              setSettlementModal(false);
            },
          }}
        >
          <Formik
            initialValues={modalData || initialState}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              if (modalData) {
                setAllSettlement((prev: any) =>
                  prev.map((s: any) =>
                    s.calimId === modalData.calimId ? values : s
                  )
                );
              } else {
                setAllSettlement((prev: any[]) => [...prev, values]);
              }
              setSubmitting(false);
              setSettlementModal(false);
            }}
          >
            {({ values, handleChange, handleSubmit, isSubmitting }) => (
              <Form className="grid w-full gap-2 p-4">
                <Input
                  type="select"
                  name="settlementType"
                  label={t('Settlement Type')}
                  settings={{
                    hint: '*' + t('required'),
                    options: [
                      { label: 'Voucher', value: 'voucher' },
                      { label: 'Credit Note', value: 'credit-note' },
                    ],
                  }}
                />
                <Input
                  type="select"
                  name="approvals"
                  label={t('Secondary Approval Required')}
                  settings={{
                    hint: '*' + t('required'),
                    options: [
                      { label: 'Yes', value: 'yes' },
                      { label: 'No', value: 'no' },
                    ],
                  }}
                />
                <Input
                  type="currency"
                  name="price"
                  label={t('Amount')}
                  settings={{
                    hint: '*' + t('required'),
                  }}
                />
                <Input
                  type="text"
                  name="calimId"
                  label={t('Claim Id')}
                  settings={{
                    hint: '*' + t('required'),
                    disabled: true,
                  }}
                />
                <div
                  className={`grid ${
                    values.settlementType === 'voucher'
                      ? 'grid-cols-2'
                      : 'grid-cols-1'
                  } gap-4`}
                >
                  <Input
                    type="datepicker"
                    name="approvalDate"
                    label={t('Approval Date')}
                    settings={{
                      hint: '*' + t('required'),
                      placeholder: 'DD/MM/YYYY',
                      maxDate: new Date(),
                    }}
                  />
                  {values.settlementType === 'voucher' && (
                    <Input
                      type="datepicker"
                      name="expiry"
                      label={t('Expiry')}
                      settings={{
                        hint: '*' + t('required'),
                        placeholder: 'DD/MM/YYYY',
                      }}
                    />
                  )}
                </div>
                <Input
                  type="rich-text"
                  name="reason"
                  label={t('Reason')}
                  settings={{}}
                />
                <div className="sticky bottom-0 flex justify-end gap-2 bg-white">
                  <Button type="submit" kind="primary" disabled={isSubmitting}>
                    {modalData ? t('Update') : t('Save')}
                  </Button>
                  <Button
                    kind="outline"
                    type="button"
                    onClick={() => {
                      setSettlementModal(false);
                    }}
                  >
                    {t('Cancel')}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal>
      )}
    </CardWrapperWithHeader>
  );
};

export default ClaimSettlementSection;
