import { useEffect, useMemo, useState } from 'react';
import ModalAddEditItem, {
  ModalAddEditItemKind,
  ModalAddEditItemMode,
} from '../../organisms/ModalNewEditProduct/ModalAddEditItem';
import { useTranslation } from 'react-i18next';
import { ButtonIcon, Modal } from '@rabbit/elements/shared-components';
import { CreateTable } from '@rabbit/sage/utils/CreateTable';
import { PencilSquareIcon } from '@heroicons/react/24/solid';
import { Query } from '@rabbit/utils/api';
import { DTWarranty_Template } from '@rabbit/data/types';
import { toast } from 'react-toastify';

export interface WarrantiesTabProps {
  handleEditModal: (
    kind: ModalAddEditItemKind,
    title: string,
    itemDetails?: any
  ) => void;
}

export function WarrantiesTab() {
  const { t } = useTranslation();
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [itemDetails, setItemDetails] = useState<any>();
  const [modalMode, setModalMode] = useState<ModalAddEditItemMode>(
    ModalAddEditItemMode.new
  );
  const [warrantyTemplates, setWarrantyTemplates] = useState<
    DTWarranty_Template[]
  >([]);
  const [loading, setLoading] = useState(false);

  const handleEditModal = (itemDetails?: any) => {
    setItemDetails(itemDetails || null);
    setModalMode(
      itemDetails ? ModalAddEditItemMode.edit : ModalAddEditItemMode.new
    );
    setEditModalOpen(true);
  };

  useEffect(() => {
    const fetchTemplates = async () => {
      setLoading(true);
      try {
        const warrantyTemplates = await Query('WarrantyTemplate')
          .where('warrantorLink', '==', `W:${t('tenantLink')}`)
          .get();
        setWarrantyTemplates(warrantyTemplates);
        console.log(warrantyTemplates);
      } catch (e) {
        console.error(e);
        toast.error('Unable to fetch templates');
      } finally {
        setLoading(false);
      }
    };

    fetchTemplates();
  }, []);

  const templateList = warrantyTemplates?.map((template) => ({
    id: template.docid,
    title: template.title,
    type: template.type,
    options: template.options,
  }));

  const WarrantyTable = useMemo(() => {
    return CreateTable<(typeof templateList)[0]>(templateList || [], [
      {
        header: t('general.title'),
        value: (template) => template.title,
      },
      {
        header: t('general.description'),
        value: (template) => template.title,
      },
      {
        header: t('general.type'),
        value: (template) => template.type,
      },
      {
        header: 'Options',
        value: (template) =>
          template.options
            ? `${template.options.optionList[0].optionName} = ${
                template.options.optionList[0].optionItems[0].itemLabel ===
                '5 years'
                  ? 'Lifetime'
                  : template.options.optionList[0].optionItems[0].itemLabel
              }`
            : '',
      },
      {
        header: '',
        accessorKey: 'actions',
        value: (cause) => (
          <div className="text-center">
            <ButtonIcon
              label={t('general.editWarranty')}
              kind={'bgLightGreen'}
              iconLeft={true}
              Icon={PencilSquareIcon}
              onClick={() => handleEditModal(cause)}
            />
          </div>
        ),
        size: 15,
        maxSize: 50,
      },
    ]);
  }, [warrantyTemplates]);

  const modalSettings = {
    kind: 'pop-up' as const,
    settings: {
      text: t('message.confirmDeleteFault'),
      primaryButtonText: t('general.yes'),
      outlineButtonText: t('general.cancel'),
      handleClose: () => setIsConfirmationModalOpen(false),
      handlePrimaryClick: async () =>
        console.log('delete --> selectedRowToDelete'),
      handleOutlineClick: () => setIsConfirmationModalOpen(false),
    },
  };

  return (
    <>
      <div className="relative z-10 flex w-full items-center justify-between py-4">
        <div className="absolute top-[60px] right-5">
          <div>
            <ButtonIcon
              label={t('general.addWarrantyTemplate')}
              iconLeft={true}
              onClick={() => handleEditModal()}
            />
          </div>
        </div>
      </div>
      <WarrantyTable
        initialState={{
          columnPinning: { right: ['actions'] },
          showGlobalFilter: true,
          isLoading: loading,
        }}
        muiSearchTextFieldProps={{
          placeholder: t('general.searchNameOrDescription'),
          sx: {
            minWidth: '600px',
            borderRadius: '20px',
            backgroundColor: '#fff',
            margin: '5px 0',
            '& .MuiOutlinedInput-root': {
              '& > fieldset': { borderRadius: '10px' },
            },
          },
          variant: 'outlined',
          margin: 'none',
        }}
      />
      {editModalOpen && (
        <div>
          <ModalAddEditItem
            handleClose={() => setEditModalOpen(false)}
            handleDelete={() => setIsConfirmationModalOpen(true)}
            itemDetails={itemDetails}
            kind={ModalAddEditItemKind.warranty}
            title={
              modalMode === ModalAddEditItemMode.edit
                ? t('general.editWarranty')
                : t('general.addWarranty')
            }
            mode={modalMode}
          />
        </div>
      )}
      {isConfirmationModalOpen && (
        <Modal
          className="h-fit w-[350px] rounded-md bg-white"
          kind={modalSettings.kind}
          settings={modalSettings.settings}
        />
      )}
    </>
  );
}
