import Chart from 'react-apexcharts';
import {
  CardWrapperWithHeader,
  chartServiceLevels,
  chartNumberOfClaims,
  chartCommonFaults,
  chartRegistrations,
  chartCategories,
  chartCasesPerTeam,
  chartRepairCosts,
  chartProducts,
  chartNewWarrantyRegistrations,
  chartNewClaimsRegistrations,
  chartAvgResponse,
  chartSaleToFaultRatio,
  chartWarrantyCostsStatus,
  Heading,
  ButtonIcon,
  FiltersModal,
} from '@rabbit/elements/shared-components';
import DashboardStats from '../components/molecules/DashboardStats/DashboardStats';
import MapChart from '../utils/MapChart';
import CardWithTitleAndChart from '../components/molecules/CardWithTitleAndChart/CardWithTitleAndChart';
import { FunnelIcon } from '@heroicons/react/24/outline';
import { useContext, useState } from 'react';
import { UserContext } from '../context/UserContext';
import { useTranslation } from 'react-i18next';
import { getUserPermissions } from '../utils/helpers';
import { Permissions } from '@rabbit/data/types';
import { Navigate } from 'react-router-dom';
import { SAGE_ROUTE_NAME } from '@rabbit/config/enums';

/* eslint-disable-next-line */
export interface DashboardProps {}

export interface FormValues {
  beginDate?: Date | undefined;
  endDate?: Date | undefined;
}

const dealerRegistrations = [
  {
    score: 57,
    store: 'Domus Construction Inc',
    rate: 10,
  },
  {
    score: 46,
    store: 'Jersey Roofing, LLC',
    rate: 8,
  },
  {
    score: 34,
    store: 'Alexander Home Improvement',
    rate: 15,
  },
];

const dealerClaims = [
  {
    score: 113,
    store: 'Domus Construction Inc',
    rate: 20,
  },
  {
    score: 97,
    store: 'Jersey Roofing, LLC',
    rate: 7,
  },
  {
    score: 76,
    store: 'Alexander Home Improvement',
    rate: 11,
  },
];

export function DashboardView(props: DashboardProps) {
  const { t } = useTranslation();
  const contextValues = useContext(UserContext);
  const tenantLink = t('tenantLink');
  const userPermissions = getUserPermissions(tenantLink, contextValues);

  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [filters, setFilters] = useState<FormValues>();

  if (
    t('tenantLink') !== 'DEMO' &&
    t('tenantLink') !== 'OWENSCORNING' &&
    !userPermissions?.includes(Permissions.DashboardView)
  )
    return <Navigate to={SAGE_ROUTE_NAME.MANAGE_ACCOUNT} />;

  const onChangeFilters = (value: {
    beginDate?: Date | undefined;
    endDate?: Date | undefined;
  }) => {
    setIsFilterModalOpen(false);
    setFilters(value);
  };

  return (
    <>
      <div className="relative z-10 my-5 flex justify-end">
        <div className="relative z-10">
          <div>
            <ButtonIcon
              label={'Filters'}
              iconLeft={true}
              Icon={FunnelIcon}
              onClick={() => setIsFilterModalOpen(true)}
              kind={'bgLightGreen'}
              count={
                (filters && filters.beginDate) || (filters && filters.endDate)
                  ? 1
                  : 0
              }
            />
          </div>
          {isFilterModalOpen && (
            <div className="top-15 absolute right-0">
              <FiltersModal
                page={'dashboard'}
                handleClose={() => setIsFilterModalOpen(false)}
                data={filters}
                onChange={onChangeFilters}
              />
            </div>
          )}
        </div>
      </div>
      <div className="mb-5 grid grid-cols-3 gap-5">
        <CardWithTitleAndChart
          score={'+8'}
          subtitle={t('message.newWarrantyRegistrations')}
          rate={10}
        >
          <Chart
            options={chartNewWarrantyRegistrations.options}
            series={chartNewWarrantyRegistrations.series}
            type="line"
          />
        </CardWithTitleAndChart>
        <CardWithTitleAndChart
          score={'+1'}
          subtitle={t('message.newClaimRegistrations')}
          rate={-6}
        >
          <Chart
            options={chartNewClaimsRegistrations.options}
            series={chartNewClaimsRegistrations.series}
            type="line"
          />
        </CardWithTitleAndChart>
        <CardWithTitleAndChart
          score={'2 days'}
          subtitle={'Avg response Time'}
          rate={10}
        >
          <Chart
            options={chartAvgResponse.options}
            series={chartAvgResponse.series}
            type="line"
          />
        </CardWithTitleAndChart>
        <DashboardStats
          title={t('Dealer registrations')}
          items={dealerRegistrations}
        />
        <DashboardStats title={'Dealer Claims'} items={dealerClaims} />
        <div className="flex flex-col gap-3 bg-gray-50 p-4">
          <Heading kind={'h3'}>Regional Hotspot</Heading>
          <MapChart />
        </div>
      </div>
      <div className="grid grid-cols-2 gap-5">
        <CardWrapperWithHeader title={t('message.openClaims')}>
          <Chart
            options={chartNumberOfClaims.options}
            series={chartNumberOfClaims.series}
            type="line"
          />
        </CardWrapperWithHeader>
        <CardWrapperWithHeader title={t('message.saleToFaultRatio')}>
          <Chart
            options={chartSaleToFaultRatio.options}
            series={chartSaleToFaultRatio.series}
            type="line"
          />
        </CardWrapperWithHeader>
        <CardWrapperWithHeader
          title={t('message.productRegistrationsThisMonth')}
        >
          <Chart
            options={chartProducts.options}
            series={chartProducts.series}
            type="bar"
          />
        </CardWrapperWithHeader>
        <CardWrapperWithHeader title={t('message.categories')}>
          <Chart
            options={chartCategories.options}
            series={chartCategories.series}
            type="bar"
          />
        </CardWrapperWithHeader>
        <CardWrapperWithHeader title={t('message.registrationsByRetailer')}>
          <Chart
            options={chartRegistrations.options}
            series={chartRegistrations.series}
            type="bar"
          />
        </CardWrapperWithHeader>
        <CardWrapperWithHeader title={t('general.faults')}>
          <Chart
            options={chartCommonFaults.options}
            series={chartCommonFaults.series}
            type="bar"
          />
        </CardWrapperWithHeader>
        <CardWrapperWithHeader title={t('message.serviceLevels')}>
          <Chart
            options={chartServiceLevels.options}
            series={chartServiceLevels.series}
            type="donut"
          />
        </CardWrapperWithHeader>
        <CardWrapperWithHeader
          title={t('message.openClosedCasesPerTeamMember')}
        >
          <Chart
            options={chartCasesPerTeam.options}
            series={chartCasesPerTeam.series}
            type="bar"
          />
        </CardWrapperWithHeader>
        <CardWrapperWithHeader
          title={t('message.repairCosts')}
          headerRight={<span className="text-lg font-bold">$175k</span>}
        >
          <Chart
            options={chartRepairCosts.options}
            series={chartRepairCosts.series}
            type="line"
          />
        </CardWrapperWithHeader>
        <CardWrapperWithHeader
          title={t('message.warrantyCostsByWarrantyStatus')}
          headerRight={<span className="text-lg font-bold">$672k</span>}
        >
          <Chart
            options={chartWarrantyCostsStatus.options}
            series={chartWarrantyCostsStatus.series}
            type="bar"
          />
        </CardWrapperWithHeader>
      </div>
    </>
  );
}

export default DashboardView;
