import {
  Button,
  ButtonIcon,
  Heading,
  Input,
  TrashIcon,
} from '@rabbit/elements/shared-components';
import { useState } from 'react';
import ModalAddEditItem from '../ModalAddEditItem';
import { WARRANTY_TYPE } from '@rabbit/bizproc/react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { FBD_Warranty_Template, WarrantyTypes } from '@rabbit/data/types';
import { FieldArray, Form, Formik } from 'formik';
import { PlusIcon } from '@heroicons/react/24/outline';
import { DeleteOutline } from '@mui/icons-material';

export function ModalAddEditWarranty({
  handleClose,
  itemDetails,
  mode,
}: ModalAddEditItem) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation();
  const [terms, setTerms] = useState<
    {
      title: string;
      term: number;
    }[]
  >([]);

  async function createTemplate(
    title: string,
    duration: { label: string; value: string },
    type: WarrantyTypes,
    terms: { title: string; term: number }[]
  ) {
    const template = FBD_Warranty_Template.empty();
    template.title = title;
    template.docid = `${t('tenantLink')}_${title.toUpperCase()}`;
    template.options = {
      pricing: '',
      pricingCurrency: 'EUR',
      optionList: [
        {
          optionKey: 'duration',
          optionItems: [
            {
              itemValue: duration.value,
              itemLabel: duration.label,
            },
          ],
        },
      ],
    };
    template.terms = terms as any;
    template.type = type;
    await FBD_Warranty_Template.set(template);
  }

  const onSubmit = async (values: any) => {
    setIsSubmitting(true);
    const durationsMap = [1, 2, 3, 4, 5];
    try {
      if (mode === 'edit' && itemDetails) {
        // todo
      }
      if (mode === 'new') {
        console.log(values);
        await createTemplate(
          values.title,
          {
            label: durationsMap[values.duration] + ' years',
            value: durationsMap[values.duration].toString(),
          },
          values.type,
          values.terms
        );
      }
      toast.success(t('message.warrantySavedSuccessfully'));
    } catch (error) {
      console.log(error);
      toast.error(t('message.errorSavingWarranty'));
    } finally {
      setIsSubmitting(false);
      handleClose();
    }
  };

  const durationOptions = [
    { label: '1 Year', value: 1 },
    { label: '2 Years', value: 2 },
    { label: '3 Years', value: 3 },
    { label: '4 Years', value: 4 },
    { label: '5 Years', value: 5 },
    { label: 'Lifetime', value: 6 },
  ];

  return (
    <div className="relative p-4">
      <Formik
        initialValues={{
          title: itemDetails ? itemDetails.title : '',
          type: itemDetails ? itemDetails.type : '',
          duration: itemDetails ? itemDetails.duration : 0,
        }}
        onSubmit={onSubmit}
        validationSchema={null}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {(props) => (
          <Form>
            <div className="grid grid-cols-2 gap-4">
              <Input
                type="text"
                label="Title"
                name="title"
                settings={{
                  placeholder: 'Enter a title',
                  value: itemDetails ? itemDetails.title : '',
                }}
              />
              <Input
                type="select"
                label="Type"
                name="type"
                settings={{
                  placeholder: 'Select warranty type',
                  options: WARRANTY_TYPE,
                }}
              />
            </div>
            <Input
              type="select"
              label="Duration"
              name="duration"
              className="mt-4"
              settings={{
                placeholder: 'Select a duration',
                options: durationOptions,
              }}
            />
            <div className="mt-4 flex flex-col rounded bg-gray-100 p-4">
              <Heading kind="h3">Coverage</Heading>
              <FieldArray name="terms">
                {({ push, remove, form }) => (
                  <>
                    {terms.map((_, index) => (
                      <div className="mt-4 grid grid-cols-[1fr_1fr_40px] items-end gap-4">
                        <Input
                          type="text"
                          label="Title"
                          name={`terms.${index}.title`}
                          settings={{
                            placeholder: 'Enter a title',
                          }}
                        />
                        <Input
                          type="select"
                          label="Term"
                          name={`terms.${index}.term`}
                          settings={{
                            placeholder: 'Enter a term',
                            options: durationOptions,
                          }}
                        />
                        <div className="mb-1">
                          <ButtonIcon
                            label=""
                            className="h-10 w-10 p-0"
                            kind="bgRed"
                            onClick={() => {
                              remove(index);
                              setTerms((t) => t.filter((_, i) => i !== index));
                            }}
                            Icon={TrashIcon}
                          />
                        </div>
                      </div>
                    ))}
                    <div className="mt-4">
                      <Button
                        kind="primary"
                        onClick={() => {
                          setTerms((t) => [...t, { title: '', term: 0 }]);
                          push({ title: '', term: '' });
                        }}
                      >
                        <PlusIcon className="mr-2 h-4 w-4" /> Add coverage
                      </Button>
                    </div>
                  </>
                )}
              </FieldArray>
            </div>
            <div className="mt-4 flex gap-4">
              <Button
                kind="primary"
                size="md"
                type="submit"
                children={
                  mode === 'edit' ? t('general.saveAll') : t('general.save')
                }
                disabled={isSubmitting}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
