import {
  DTConsumer_Private,
  DTHoldingProxy,
  Warranty,
} from '@rabbit/data/types';
import {
  CardWrapperWithHeader,
  formatUnixTime,
  Table,
} from '@rabbit/elements/shared-components';
import { useTranslation } from 'react-i18next';
import { BL_Warranty } from '@rabbit/bizproc/core';
import { format, getUnixTime } from 'date-fns';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { getWarrantyTermsData } from '@rabbit/sage/utils/helpers.tsx';

interface WarrantyHoldingDetailsSectionProps {
  allHoldingsList?: DTHoldingProxy[] | null;
  consumer_private?: DTConsumer_Private | null;
  holding_proxy?: DTHoldingProxy | null;
  holdingId: string;
  storeAddress: string;
}

const tableHeaders = (t: any) => [
  {
    header: t('general.warranty'),
    accessorKey: 'warranty',
    size: 100,
  },
  {
    header: t('general.coverage'),
    accessorKey: 'coverage',
    size: 200,
  },
  {
    header: t('general.term'),
    accessorKey: 'term',
    size: 200,
  },
  {
    header: t('general.startDate'),
    accessorKey: 'start_date',
    size: 15,
  },
  {
    header: t('general.endDate'),
    accessorKey: 'end_date',
    size: 15,
  },
];

const tableHeadersTerms = (t: any) => [
  {
    header: t('Coverage Aspect'),
    accessorKey: 'category',
    size: 10,
  },
  {
    header: t('general.description'),
    accessorKey: 'details',
    size: 500,
  },
];

const formatResultsForTerms = (warrantyTerms: any) => {
  const results = [];
  if (warrantyTerms) {
    const termsHeader = Object.keys(warrantyTerms);

    for (let i = 0; i < termsHeader.length; i++) {
      const termSingle = warrantyTerms[termsHeader[i]];
      const termSingleObj = Object.keys(termSingle);
      results[i] = {
        category: termsHeader[i].replace(/([A-Z])/g, ' $1').trim(),
        details: '',
        subRows: [] as any,
      };
      for (let j = 0; j < termSingleObj.length; j++) {
        if (results[i] && results[i].subRows) {
          results[i].subRows.push({
            category: termSingleObj[j].replace(/([A-Z])/g, ' $1').trim(),
            details: (
              <div
                dangerouslySetInnerHTML={{
                  __html: termSingle[termSingleObj[j]],
                }}
              ></div>
            ),
          });
        }
      }
    }
  }
  return results;
};

const formatResultsForPartnersTable = (warrantyInfo: Warranty) => {
  return [
    {
      warranty: 'Basic',
      coverage: 'Defects',
      term: '12 months',
      start_date: warrantyInfo?.startDate
        ? formatUnixTime(getUnixTime(warrantyInfo.startDate), 'dd/MM/yyyy')
        : '-',
      end_date: warrantyInfo?.startDate
        ? moment
            .unix(warrantyInfo.startDate / 1000)
            .add('12', 'months')
            .subtract('1', 'day')
            .format('DD/MM/YY')
        : '-',
      mileage_limit: '120,000 kms',
    },
    {
      warranty: 'Structural',
      coverage: 'Fixed components',
      term: '60 months',
      start_date: warrantyInfo?.startDate
        ? formatUnixTime(getUnixTime(warrantyInfo.startDate), 'dd/MM/yyyy')
        : '-',
      end_date: warrantyInfo?.startDate
        ? moment
            .unix(warrantyInfo.startDate / 1000)
            .add('60', 'months')
            .subtract('1', 'day')
            .format('DD/MM/YY')
        : '-',
      mileage_limit: '600,000 kms',
    },
    {
      warranty: 'Structural',
      coverage: 'Moving components',
      term: '24 months',
      start_date: warrantyInfo?.startDate
        ? formatUnixTime(getUnixTime(warrantyInfo.startDate), 'dd/MM/yyyy')
        : '-',
      end_date: warrantyInfo?.startDate
        ? moment
            .unix(warrantyInfo.startDate / 1000)
            .add('24', 'months')
            .subtract('1', 'day')
            .format('DD/MM/YY')
        : '-',
      mileage_limit: '240,000 kms',
    },
    {
      warranty: 'Paint',
      coverage: 'Rust perforation from defects',
      term: '60 months',
      start_date: warrantyInfo?.startDate
        ? formatUnixTime(getUnixTime(warrantyInfo.startDate), 'dd/MM/yyyy')
        : '-',
      end_date: warrantyInfo?.startDate
        ? moment
            .unix(warrantyInfo.startDate / 1000)
            .add('60', 'months')
            .subtract('1', 'day')
            .format('DD/MM/YY')
        : '-',
      mileage_limit: '600,000 kms',
    },
    {
      warranty: 'Repairs',
      coverage: 'Repaired or replacement part defects',
      term: '90 days',
      start_date: '',
      end_date: '',
      mileage_limit: '',
    },
  ];
};
const standardWarrantyTemplate = (warrantyInfo: Warranty) => {
  return [
    {
      warranty: 'Standard Protection',
      coverage: 'Products Defects',
      term: 'Lifetime',
      start_date: warrantyInfo?.startDate
        ? formatUnixTime(getUnixTime(warrantyInfo.startDate), 'dd/MM/yyyy')
        : '-',
      end_date: 'Lifetime',
    },
    {
      warranty: '',
      coverage: 'TRU PROtection® Period',
      term: 'First 10 Years',
      start_date: warrantyInfo?.startDate
        ? formatUnixTime(getUnixTime(warrantyInfo.startDate), 'dd/MM/yyyy')
        : '-',
      end_date: warrantyInfo?.startDate
        ? moment
            .unix(warrantyInfo.startDate / 1000)
            .add('10', 'years')
            .subtract('1', 'day')
            .format('DD/MM/YYYY')
        : '-',
    },
    {
      warranty: '',
      coverage: 'Materials Only Coverage',
      term: 'After 10 Years',
      start_date: warrantyInfo?.startDate
        ? moment
            .unix(warrantyInfo.startDate / 1000)
            .add('10', 'years')
            .format('DD/MM/YYYY')
        : '-',
      end_date: warrantyInfo?.startDate
        ? moment
            .unix(warrantyInfo.startDate / 1000)
            .add('20', 'years')
            .subtract('1', 'day')
            .format('DD/MM/YYYY')
        : '-',
    },
    {
      warranty: '',
      coverage: 'Blow Off Coverage³',
      term: 'First 15 Years',
      start_date: warrantyInfo?.startDate
        ? formatUnixTime(getUnixTime(warrantyInfo.startDate), 'dd/MM/yyyy')
        : '-',
      end_date: warrantyInfo?.startDate
        ? moment
            .unix(warrantyInfo.startDate / 1000)
            .add('15', 'years')
            .subtract('1', 'day')
            .format('DD/MM/YYYY')
        : '-',
    },
    {
      warranty: '',
      coverage: 'StreakGuard® Algae Resistance⁴',
      term: 'First 25 Years',
      start_date: warrantyInfo?.startDate
        ? formatUnixTime(getUnixTime(warrantyInfo.startDate), 'dd/MM/yyyy')
        : '-',
      end_date: warrantyInfo?.startDate
        ? moment
            .unix(warrantyInfo.startDate / 1000)
            .add('25', 'years')
            .subtract('1', 'day')
            .format('DD/MM/YYYY')
        : '-',
    },
    {
      warranty: '',
      coverage: 'Workmanship Coverage - Coverage Period',
      term: 'None',
      start_date: '',
      end_date: '',
      mileage_limit: '',
    },
    {
      warranty: '',
      coverage: 'Key Requirements - Component Products',
      term: 'Minimum of 3 OC® components required, for the additional coverage period to apply',
      start_date: '',
      end_date: '',
      mileage_limit: '',
    },
    {
      warranty: '',
      coverage: 'Key Requirements - Contractor Certification Required',
      term: 'None',
      start_date: '',
      end_date: '',
      mileage_limit: '',
    },
  ];
};

const platinumProtectionTemplate = (warrantyInfo: Warranty) => {
  return [
    {
      warranty: 'Platinum Protection',
      coverage: 'Products Defects',
      term: 'Lifetime',
      start_date: warrantyInfo?.startDate
        ? formatUnixTime(getUnixTime(warrantyInfo.startDate), 'dd/MM/yyyy')
        : '-',
      end_date: 'Lifetime',
    },
    {
      warranty: '',
      coverage: 'TRU PROtection® Period',
      term: 'First 50 Years',
      start_date: warrantyInfo?.startDate
        ? formatUnixTime(getUnixTime(warrantyInfo.startDate), 'dd/MM/yyyy')
        : '-',
      end_date: warrantyInfo?.startDate
        ? moment
            .unix(warrantyInfo.startDate / 1000)
            .add('50', 'years')
            .subtract('1', 'day')
            .format('DD/MM/YYYY')
        : '-',
    },
    {
      warranty: '',
      coverage: 'Materials Only Coverage',
      term: 'After 50 Years',
      start_date: warrantyInfo?.startDate
        ? moment
            .unix(warrantyInfo.startDate / 1000)
            .add('50', 'years')
            .format('DD/MM/YYYY')
        : '-',
      end_date: warrantyInfo?.startDate
        ? moment
            .unix(warrantyInfo.startDate / 1000)
            .add('100', 'years')
            .subtract('1', 'day')
            .format('DD/MM/YYYY')
        : '-',
    },
    {
      warranty: '',
      coverage: 'Blow Off Coverage³',
      term: 'First 15 Years',
      start_date: warrantyInfo?.startDate
        ? formatUnixTime(getUnixTime(warrantyInfo.startDate), 'dd/MM/yyyy')
        : '-',
      end_date: warrantyInfo?.startDate
        ? moment
            .unix(warrantyInfo.startDate / 1000)
            .add('15', 'years')
            .subtract('1', 'day')
            .format('DD/MM/YYYY')
        : '-',
    },
    {
      warranty: '',
      coverage: 'StreakGuard® Algae Resistance⁴',
      term: 'First 25 Years',
      start_date: warrantyInfo?.startDate
        ? formatUnixTime(getUnixTime(warrantyInfo.startDate), 'dd/MM/yyyy')
        : '-',
      end_date: warrantyInfo?.startDate
        ? moment
            .unix(warrantyInfo.startDate / 1000)
            .add('25', 'years')
            .subtract('1', 'day')
            .format('DD/MM/YYYY')
        : '-',
    },
    {
      warranty: '',
      coverage: 'Workmanship Coverage - Coverage Period',
      term: 'Lifetime with coverage reduced after 25 years based on the age of the roof',
      start_date: '',
      end_date: '',
      mileage_limit: '',
    },
    {
      warranty: '',
      coverage: 'Key Requirements - Component Products',
      term: 'Minimum of 4 OC® components required - 1 Hip and Ridge, 1 Underlayment and 2 of any others',
      start_date: '',
      end_date: '',
      mileage_limit: '',
    },
    {
      warranty: '',
      coverage: 'Key Requirements - Contractor Certification Required',
      term: 'Must be installed by an OC Roofing Platinum Contractor',
      start_date: '',
      end_date: '',
      mileage_limit: '',
    },
  ];
};

export default function WarrantyDetailsSection({
  allHoldingsList,
  holding_proxy,
  consumer_private,
  holdingId,
  storeAddress,
}: WarrantyHoldingDetailsSectionProps) {
  const { t } = useTranslation();
  const warrantyInfo = BL_Warranty.getLatestWarranty(holding_proxy?.warranties);
  const [warrantyTerms, setWarrantyTerms] = useState<any>();

  useEffect(() => {
    if (window) window.localStorage.removeItem('LRP_VendableId');
    const getWarranty = async () => {
      const warranty = await getWarrantyTermsData(t('tenantLink'));
      if (warranty) {
        if (warrantyInfo?.templateTitle === 'Standard Masonite Door Warranty') {
          setWarrantyTerms(warranty.warranty_terms_masonite);
        } else if (
          warrantyInfo?.templateTitle === 'Standard Endura Door Warranty'
        ) {
          setWarrantyTerms(warranty.warranty_terms_endura);
        } else {
          setWarrantyTerms(warranty.warranty_terms);
        }
      }
    };
    void getWarranty();
  }, [t('tenantLink'), warrantyInfo]);

  return (
    <>
      {(warrantyInfo?.templateTitle === 'Standard Protection' ||
        warrantyInfo?.templateTitle === 'Platinum Protection' ||
        warrantyInfo?.templateTitle === 'Standard Roof Warranty' ||
        warrantyInfo?.templateTitle === 'Platinum Roof Warranty') && (
        <CardWrapperWithHeader title={`${t('general.warrantyTerms')}`}>
          <div>
            <Table
              enableSearch={false}
              enablePagination={false}
              enableRowSelection={false}
              enableRowExpansion={false}
              enableSorting={false}
              enableTopToolbar={false}
              columns={tableHeaders(t)}
              data={
                warrantyInfo?.templateTitle === 'Standard Protection' ||
                warrantyInfo?.templateTitle === 'Standard Roof Warranty'
                  ? standardWarrantyTemplate(warrantyInfo!)
                  : platinumProtectionTemplate(warrantyInfo!)
              }
              initialState={{
                columnPinning: { right: ['actions'] },
                showCustomFilter: false,
              }}
            />
          </div>
        </CardWrapperWithHeader>
      )}

      <CardWrapperWithHeader title={t('general.warrantySummary')}>
        <div>
          <Table
            enableSearch={false}
            enablePagination={false}
            enableRowSelection={false}
            enableRowExpansion={false}
            enableSorting={false}
            enableTopToolbar={false}
            columns={tableHeadersTerms(t)}
            enableExpanding={true}
            enableExpandingAll={true}
            data={formatResultsForTerms(warrantyTerms!)}
            initialState={{
              density: 'compact',
              columnPinning: { right: ['actions'] },
              showCustomFilter: false,
            }}
          />
        </div>
      </CardWrapperWithHeader>
    </>
  );
}
